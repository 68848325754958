import { Peer, Role } from "@ameelio/connect-call-client";
import { range } from "@ameelio/core";
import {
  belowLargeTablet,
  FluidGrid,
  FluidGridItem,
  SelectInputBase,
} from "@ameelio/ui";
import { LockOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import { Entitlement, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { getFirstNames } from "@src/lib/Call";
import EmptyPage from "@src/lib/EmptyPage";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import Audio from "@src/lib/LiveCall/Audio";
import { useCallRegistry } from "@src/lib/LiveCall/CallRegistry";
import UserLabel from "@src/lib/LiveCall/UserLabel";
import Video from "@src/lib/LiveCall/Video";
import VideoCall from "@src/lib/LiveCall/VideoCall";
import MessageDisplay from "@src/lib/MessageDisplay";
import NotAllowed from "@src/lib/NotAllowed";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { openNotificationWithIcon } from "@src/lib/UI";
import useEntitlement from "@src/lib/useEntitlement";
import useLiveCalls, { Call } from "@src/lib/useLiveCalls";
import { Layout, PageHeader, Pagination } from "antd";
import { omit } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ConfidentialVideo from "./ConfidentialVideo";
import { TerminateCallDocument } from "./TerminateMeeting.generated";
import VideoConnecting from "./VideoConnecting";

const OPTIONS = [1, 2, 4, 6, 8] as const;
type GridOption = typeof OPTIONS[number];

type PeerViewProps = {
  peer: Peer;
  isMuted: boolean;
  name: string;
};

const PeerView: React.FC<PeerViewProps> = ({
  peer,
  isMuted,
  name,
}: PeerViewProps) => {
  const [wasHidden, setWasHidden] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (peer.manualConsumerPauses.video) setWasHidden(true);
  }, [peer]);

  // We can come out of the "paused for bandwidth" state in two ways:
  // either they actually pause (so blank screen is in fact what is being sent, too)
  // or we start receiving video from them.
  useEffect(() => {
    if (!peer.manualConsumerPauses.video && peer.consumers.video?.paused) {
      setWasHidden(false);
    }
  }, [peer]);

  const onPlaying = useCallback(() => {
    const checkLoop = () => {
      if (videoRef.current && videoRef.current.videoWidth > 5) {
        setWasHidden(false);
      } else {
        setTimeout(checkLoop, 10);
      }
    };

    checkLoop();
  }, []);

  return (
    <FluidGridItem
      key={peer.user.id}
      sx={{
        position: "relative",
        borderRadius: 1,
      }}
    >
      {wasHidden && <CircularProgress size={30} />}
      <Video
        srcObject={peer.consumers.video?.stream}
        autoPlay
        underlyingRef={videoRef}
        onPlaying={onPlaying}
        style={{
          display: wasHidden ? "none" : "block",
          maxHeight: "100%",
          maxWidth: "100%",
          objectFit: "contain",
        }}
      />
      <Audio
        autoPlay
        srcObject={peer.consumers.audio?.stream}
        muted={isMuted}
      />
      <UserLabel
        name={name}
        isMuted={peer.consumers.audio?.paused !== false}
        isHidden={peer.consumers.video?.paused !== false}
      />
    </FluidGridItem>
  );
};

const LiveVisitationContainer: React.FC = () => {
  const [activeCallChat, setActiveCallChat] = useState<Call>();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);
  const [chatCollapsed, setChatCollapsed] = useState(false);
  const [grid, setGrid] = useState<GridOption>(1);
  const [page, setPage] = useState(1);
  const { facility } = useGuaranteedFacilityContext();
  const canMonitorLiveCalls = useEntitlement(Entitlement.MonitorLiveCalls);
  const [terminateMeeting] = useMutation(TerminateCallDocument, {
    onError: errorReporter,
  });

  // figure out which calls are alive in both the API and CVH
  const { registry, setVisible } = useCallRegistry();
  const calls = useLiveCalls({
    facility,
    meetingTypes: [MeetingType.VideoCall],
  });

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  // map from call id to muted boolean
  const [unmutedCallsMap, setUnmutedCalls] = useState<Record<number, boolean>>(
    {}
  );

  const activeMessages = useMemo(
    () => (activeCallChat ? registry[activeCallChat.id]?.messages || [] : []),
    [activeCallChat, registry]
  );

  useEffect(() => {
    if (!messagesContainerRef.current) return;
    messagesContainerRef.current.scroll({
      top: messagesContainerRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [messagesContainerRef, activeMessages]);

  // Initialize call messages sider
  useEffect(() => {
    if (!calls.length) {
      setActiveCallChat(undefined);
      return;
    }
    if (!activeCallChat) setActiveCallChat(calls[0]);
  }, [calls, activeCallChat]);

  const onPageChange = (page: number, _?: number) => {
    setPage(page);
  };

  const visibleCalls = useMemo(
    () =>
      range(grid)
        .map((_, idx) => {
          const offset = (page - 1) * grid;
          return offset + idx;
        })
        .filter((x) => x < calls.length)
        .map((x) => calls[x]),
    [page, grid, calls]
  );

  useEffect(() => {
    setVisible(new Set(visibleCalls.map((x) => x.id)));
  }, [visibleCalls, setVisible]);

  const scrollToFocus = useCallback((node) => {
    node &&
      node.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, []);

  const getParticipantNames = useCallback(
    (call: Call) =>
      Object.fromEntries(
        [...call.visitors, ...call.inmates].map((p) => [p.id, p.fullName])
      ),
    []
  );

  const { t } = useTranslation();

  const onTerminateCall = (callId: string) => {
    return terminateMeeting({
      variables: {
        input: {
          meetingId: callId,
        },
      },
    }).then(
      () =>
        openNotificationWithIcon(
          t("Call terminated"),
          t("We notified both participants of the incident."),
          "info"
        ),
      (e) =>
        openNotificationWithIcon(
          t("Could not terminate."),
          `${t("Error message")}: ${e}`,
          "error"
        )
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        maxHeight: { xs: "none", md: "100vh" },
      }}
    >
      <Header
        title={`${t("Live Calls")} (${calls.length})`}
        subtitle={t(
          "Monitor, send alerts and terminate calls if needed. All in real-time."
        )}
      />

      {!canMonitorLiveCalls ? (
        <NotAllowed />
      ) : calls.length === 0 ? (
        <EmptyPage description={t("There currently aren't any live calls.")} />
      ) : (
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              flex: 1,
              p: 3,
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: "500px", md: "inherit" },
            }}
          >
            {calls.length > 0 && (
              <Box pb={2}>
                <Pagination
                  current={page}
                  defaultCurrent={1}
                  defaultPageSize={grid}
                  onChange={onPageChange}
                  pageSize={grid}
                  pageSizeOptions={OPTIONS.map((e) => `${e}`)}
                  total={calls.length}
                  showSizeChanger={true}
                  onShowSizeChange={(_, size) => setGrid(size as GridOption)}
                />
              </Box>
            )}
            <Box
              sx={{
                flex: 1,
                overflow: "hidden",
                display: "grid",
                gap: 2,
                gridTemplateRows: grid > 2 ? "1fr 1fr" : "1fr",
                gridAutoFlow: "column",
                gridAutoColumns: "1fr",
              }}
            >
              {visibleCalls.map((call, idx) => {
                if (call.privacyLevel !== PrivacyLevel.Monitored)
                  return (
                    <ConfidentialVideo
                      key={call.id}
                      participantNamesById={getParticipantNames(call)}
                      scheduledEnd={call.interval.endAt}
                      kioskName={call.kiosk?.name || ""}
                      onTerminate={() => onTerminateCall(call.id)}
                    />
                  );

                // live, not confidential, but no connection
                if (!registry[call.id])
                  return (
                    <Box sx={{ position: "relative" }} key={call.id}>
                      <VideoConnecting height="100%" />
                    </Box>
                  );

                // live and connected
                const isMuted = !(call.id in unmutedCallsMap);
                const peers = [...Object.values(registry[call.id].peers)]
                  .filter((p) => p.user.role !== Role.monitor)
                  .sort((a, b) => a.user.id.localeCompare(b.user.id));
                return (
                  <VideoCall
                    key={call.id}
                    showSendAlert={call.privacyLevel === PrivacyLevel.Monitored}
                    onToggleMute={() =>
                      isMuted
                        ? setUnmutedCalls({
                            ...unmutedCallsMap,
                            [call.id]: true,
                          })
                        : setUnmutedCalls(omit(unmutedCallsMap, call.id))
                    }
                    isMuted={isMuted}
                    onToggleChat={() => {
                      if (chatCollapsed) setActiveCallChat(call);
                      setChatCollapsed(!chatCollapsed);
                    }}
                    chatCollapsed={chatCollapsed}
                    pinCall={() => {
                      setGrid(1);
                      setPage(grid * (page - 1) + idx + 1);
                      setActiveCallChat(calls[idx]);
                    }}
                    onAlert={async (message) => {
                      try {
                        await registry[call.id].sendMessage(message);
                        openNotificationWithIcon(
                          t("Alert successfully issued."),
                          t("Both parties have been notified."),
                          "success"
                        );
                      } catch (e) {
                        openNotificationWithIcon(
                          t("Alert could not be sent."),
                          `${t("Error message")}: ${e}`,
                          "error"
                        );
                      }
                    }}
                    onTerminate={() => onTerminateCall(call.id)}
                    scheduledEnd={call.interval.endAt}
                    kioskName={call.kiosk?.name || ""}
                    sx={{
                      flex: 1,
                      display: "flex",
                      overflow: "hidden",
                      pt: 7,
                    }}
                  >
                    <FluidGrid size={2} gap={2} sxOverrides={{ p: 0 }}>
                      {peers.map((peer) => (
                        <PeerView
                          key={peer.peerId}
                          peer={peer}
                          isMuted={isMuted}
                          name={getParticipantNames(call)[peer.user.id]}
                        />
                      ))}
                    </FluidGrid>
                  </VideoCall>
                );
              })}
            </Box>
          </Box>
          <Layout.Sider
            theme="light"
            width={isMobileOrSmallTablet ? "100%" : 300}
            collapsible={!isMobileOrSmallTablet}
            collapsedWidth={isMobileOrSmallTablet ? "100%" : 80}
            defaultCollapsed
            reverseArrow
            collapsed={chatCollapsed}
            onCollapse={(collapsed) => setChatCollapsed(collapsed)}
            className="shadow overflow-y-auto"
            style={{ flex: 1, margin: "0 auto" }}
          >
            {!chatCollapsed && (
              <div ref={messagesContainerRef} className="relative">
                <PageHeader
                  title={t("Chat")}
                  extra={[
                    calls && calls.length ? (
                      <SelectInputBase
                        size="small"
                        aria-label={t("Chat")}
                        key="chat-select"
                        value={activeCallChat?.id}
                        onChange={(event) => {
                          const id = event.target.value as string;
                          setActiveCallChat(calls.find((v) => v.id === id));
                        }}
                        items={calls.map((c) => ({
                          value: c.id,
                          name:
                            getFirstNames(c.inmates) +
                            " & " +
                            getFirstNames(c.visitors),
                        }))}
                      />
                    ) : null,
                  ]}
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                  }}
                />
                <Box px={3} className="pb-16">
                  {activeCallChat &&
                  activeCallChat?.privacyLevel !== PrivacyLevel.Monitored ? (
                    <>
                      <Box mb={2}>
                        <LockOutlined />
                      </Box>
                      <Typography variant="body2" color="text.primary">
                        {t(
                          "Messages are not available on confidential meetings."
                        )}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {activeCallChat &&
                        activeMessages.map((message, idx) => {
                          const sender =
                            activeCallChat.inmates.find(
                              (i) => i.id === message.user.id
                            ) ||
                            activeCallChat.visitors.find(
                              (v) => v.id === message.user.id
                            );
                          return (
                            <div
                              key={`${message.timestamp}_${idx}`}
                              ref={
                                !isMobileOrSmallTablet &&
                                idx === activeMessages.length - 1
                                  ? scrollToFocus
                                  : undefined
                              }
                            >
                              <MessageDisplay
                                message={{
                                  callId: activeCallChat.id,
                                  senderType: sender?.__typename || "Staff",
                                  senderName:
                                    message.user.role === Role.monitor
                                      ? t("Facility Staff")
                                      : sender?.fullName || "",
                                  contents: message.contents,
                                  createdAt: message.timestamp.getTime(),
                                }}
                                className="mt-4"
                              />
                            </div>
                          );
                        })}
                    </>
                  )}
                </Box>
              </div>
            )}
          </Layout.Sider>
        </Box>
      )}
    </Box>
  );
};

export default LiveVisitationContainer;
