import { Empty as Base } from "antd";

type Props = {
  className?: string;
  description?: string;
};

export default function Empty({ description, className }: Props) {
  return <Base className={className} description={description} />;
}
