import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Box } from "@mui/material";
import { Visitor } from "@src/api/graphql";
import PageLoader from "@src/lib/PageLoader";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";
import Avatar from "../../lib/Avatar";

interface Props {
  visitors: Pick<Visitor, "id" | "fullName" | "email">[];
  loading?: boolean;
  totalCount?: number;
}

export default function VisitorTable({ visitors, loading, totalCount }: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: visitors,
  });

  return loading ? (
    <Box p={3}>
      {totalCount ? (
        <PageLoader
          title={`Loading ${visitors.length}/${totalCount} visitors...`}
        />
      ) : (
        <PageLoader title={`Loading...`} />
      )}
    </Box>
  ) : (
    <Box p={3}>
      <TableContainer>
        <Table aria-label={t("Visitors table")}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData.map(({ id, fullName, email }) => (
              <TableRow key={id}>
                <TableCell>
                  <Avatar fallback={fullName} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {fullName}
                </TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/visitors/${id}`)}
                  >
                    {t("See details")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={visitors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </Box>
  );
}
