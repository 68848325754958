import { Box, Stack } from "@mui/material";
import { ScreenSubtitle, ScreenTitle } from "@src/lib/typography";
import { FULL_WIDTH, PADDING } from "@src/styles/styles";
import { Breadcrumb } from "antd";
import * as React from "react";
import { ReactElement, ReactNode } from "react";

interface Props {
  title: string;
  subtitle?: string;
  breadcrumb?: React.ReactElement<typeof Breadcrumb>;
  extra?: ReactNode[];
  children?: ReactNode;
}

const HEADER_STYLE = {
  backgroundColor: "white",
  paddingLeft: PADDING,
  paddingRight: PADDING,
  paddingTop: "16px",
  paddingBottom: "16px",
  display: "flex",
  alignItems: "center",
};

export default function Header({
  title,
  subtitle,
  extra,
  children,
  breadcrumb,
}: Props): ReactElement {
  return (
    <Box
      style={{
        ...HEADER_STYLE,
        height: "auto",
      }}
    >
      <Stack spacing={1} style={FULL_WIDTH}>
        {breadcrumb || null}
        <ScreenTitle>{title}</ScreenTitle>
        {subtitle && <ScreenSubtitle>{subtitle}</ScreenSubtitle>}
        {children ? (
          <Box sx={{ borderTop: 1, borderColor: "divider", pt: 2 }}>
            {children}
          </Box>
        ) : extra?.length ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{ borderTop: 1, borderColor: "divider", pt: 2 }}
          >
            {extra?.map((item, idx) => (
              <Box key={idx} display="flex" alignItems="flex-end">
                {item}
              </Box>
            ))}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
}
