import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { Box } from "@mui/material";
import { Group, Inmate } from "@src/api/graphql";
import PageLoader from "@src/lib/PageLoader";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { useTranslation } from "react-i18next";
import Avatar from "../../lib/Avatar";

interface Props {
  inmates: (Pick<Inmate, "id" | "fullName" | "inmateIdentification"> & {
    group: Pick<Group, "name">;
  })[];
  loading?: boolean;
  totalCount?: number;
}

export default function InmateTable({ inmates, loading, totalCount }: Props) {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: inmates,
  });

  return loading ? (
    <Box p={3}>
      {totalCount ? (
        <PageLoader
          title={t(
            "Loading {{inmateCount}} out of {{totalCount}} residents...",
            {
              inmateCount: inmates.length,
              totalCount,
            }
          )}
        />
      ) : (
        <PageLoader />
      )}
    </Box>
  ) : (
    <Box p={3}>
      <TableContainer>
        <Table aria-label={t("Residents table")}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Unique ID")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Location")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData.map(
              ({ id, fullName, group, inmateIdentification }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Avatar fallback={fullName} />
                  </TableCell>
                  <TableCell>{inmateIdentification}</TableCell>
                  <TableCell component="th" scope="row">
                    {fullName}
                  </TableCell>
                  <TableCell>{group.name}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/inmates/${id}`)}
                    >
                      {t("See details")}
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        totalCount={inmates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </Box>
  );
}
