import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps as BaseProps,
  SubmitButton,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { appendItem } from "@src/api/client";
import {
  Entitlement,
  FacilityApprovedPhone,
  PrivacyLevel,
} from "@src/api/graphql";
import ConditionalTooltip from "@src/lib/ConditionalTooltip";
import errorReporter from "@src/lib/errorReporter";
import {
  HiddenPrivacyLabel,
  UnmonitoredPrivacyLabel,
} from "@src/lib/privacyLabels";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import {
  hasMaxLength,
  isPhoneNumber,
  isRequired,
  mergeRules,
} from "@src/lib/validate";
import { MuiTelInput } from "mui-tel-input";
import { MuiTelInputCountry } from "mui-tel-input/dist/shared/constants/countries";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextInput from "../../TextInput";
import { CreateFacilityApprovedPhoneDocument } from "./CreateFacilityApprovedPhone.generated";
import { UpdateFacilityApprovedPhoneDocument } from "./UpdateFacilityApprovedPhone.generated";

type PhoneNumber = Omit<FacilityApprovedPhone, "facility">;

export type TextFormData = {
  name: string;
  number: string;
  description: string;
  privacyLevel: PrivacyLevel;
};

type Props = Omit<BaseProps, "open" | "onSubmit" | "title"> & {
  phoneNumber?: PhoneNumber;
  onClose: () => void;
};

export default function AddEditPhoneNumberModal({
  onClose,
  phoneNumber,
  children,
  ...rest
}: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    control,
    setFocus,
  } = useForm<TextFormData>({
    mode: "onBlur",
    defaultValues: {
      name: phoneNumber?.name || "",
      number: phoneNumber?.number || "",
      description: phoneNumber?.description || "",
      privacyLevel: phoneNumber?.privacyLevel || PrivacyLevel.UnmonitoredLogged,
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setFocus("name");
    }, 0);
  }, [setFocus]);

  const [updatePhoneNumber] = useMutation(UpdateFacilityApprovedPhoneDocument, {
    onError: errorReporter,
  });

  const [createPhoneNumber] = useMutation(CreateFacilityApprovedPhoneDocument, {
    onError: errorReporter,
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ __typename: "Facility", id: facility.id }),
          fields: {
            approvedPhones: appendItem(
              data.createFacilityApprovedPhone.facilityApprovedPhone
            ),
          },
        });
      }
    },
  });

  const onFormSubmit = handleSubmit(async ({ number, ...data }) => {
    if (phoneNumber) {
      await updatePhoneNumber({
        variables: {
          input: {
            facilityApprovedPhoneId: phoneNumber.id,
            ...data,
          },
        },
      });
    } else {
      await createPhoneNumber({
        variables: {
          input: {
            facilityId: facility.id,
            number,
            ...data,
          },
        },
      });
    }

    onClose();
  });

  return (
    <Dialog
      onClose={onClose}
      {...rest}
      title={t("Approved facility contacts")}
      fullWidth
    >
      <form onSubmit={onFormSubmit}>
        <DialogContent>
          <Typography variant="body1" color="text.secondary">
            {t("These are the contacts that every resident has access to.")}
          </Typography>
          <Stack spacing={2}>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit phone numbers.")}
            >
              <TextInput
                sx={{ mt: 2, width: 1 }}
                control={control}
                name="name"
                type="tel"
                label={t("Name")}
                placeholder={""}
                disabled={!canEdit}
                rules={mergeRules(
                  isRequired(t("Please provide a name.")),
                  hasMaxLength(
                    100,
                    t("Name can be at most {{length}} characters.", {
                      length: 100,
                    })
                  )
                )}
                helperText={errors.name ? errors.name.message : ""}
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit phone numbers.")}
            >
              <Controller
                control={control}
                name="number"
                render={({ field }) => (
                  <MuiTelInput
                    sx={{ mt: 2, width: 1 }}
                    defaultCountry={
                      (facility.country || "US") as MuiTelInputCountry
                    }
                    label={t("Number")}
                    placeholder={"+1 555 555 5555"}
                    disabled={!canEdit || !!phoneNumber}
                    error={!!errors.number}
                    helperText={errors.number ? errors.number.message : ""}
                    {...field}
                  />
                )}
                rules={mergeRules(
                  isRequired(t("Please enter a valid phone number.")),
                  isPhoneNumber()
                )}
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit phone numbers.")}
            >
              <TextInput
                sx={{ mt: 2, width: 1 }}
                control={control}
                multiline
                maxRows={4}
                name="description"
                label={t("Description")}
                placeholder={""}
                disabled={!canEdit}
                rules={mergeRules(
                  isRequired(t("Please provide a description.")),
                  hasMaxLength(
                    500,
                    t("Description can be at most {{length}} characters.", {
                      length: 500,
                    })
                  )
                )}
                helperText={
                  errors.description
                    ? errors.description.message
                    : t("The residents will be able to see this.")
                }
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit phone numbers.")}
            >
              <Controller
                name="privacyLevel"
                control={control}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        sx={{ alignItems: "flex-start" }}
                        value={PrivacyLevel.UnmonitoredLogged}
                        control={<Radio />}
                        label={<UnmonitoredPrivacyLabel />}
                      />
                      <FormControlLabel
                        sx={{ alignItems: "flex-start" }}
                        value={PrivacyLevel.Hidden}
                        control={<Radio />}
                        label={<HiddenPrivacyLabel />}
                      />
                    </RadioGroup>
                  );
                }}
              />
            </ConditionalTooltip>
          </Stack>
        </DialogContent>
        <DialogActions>
          {canEdit ? (
            <>
              <Button autoFocus variant="outlined" onClick={onClose}>
                {t("Cancel")}
              </Button>
              <SubmitButton disabled={!isValid} submitting={isSubmitting}>
                {t("Save")}
              </SubmitButton>
            </>
          ) : (
            <Button autoFocus onClick={onClose}>
              {t("Close")}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
