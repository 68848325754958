import { useQuery } from "@apollo/client";
import errorReporter from "@src/lib/errorReporter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useFetchAll from "@src/lib/useFetchAll";
import { useMemo, useState } from "react";
import { GetFacilityInmatesDocument } from "./GetFacilityInmates.generated";
import Inmates from "./Inmates";

const InmatesPage = () => {
  const { facility } = useGuaranteedFacilityContext();

  const [completed, setCompleted] = useState(false);

  const { data, loading, error, fetchMore } = useQuery(
    GetFacilityInmatesDocument,
    {
      variables: {
        id: facility.id,
        cursor: undefined,
      },
      onError: errorReporter,
    }
  );
  if (error) throw error;

  useFetchAll({
    pageInfo: data?.facility.inmates.pageInfo,
    fetchMore,
    onComplete: () => setCompleted(true),
  });

  const inmates = useMemo(
    () => data?.facility.inmates.edges.map((e) => e.node) || [],
    [data]
  );

  return (
    <Inmates
      inmates={inmates}
      loading={loading || !completed}
      totalCount={data?.facility.inmates.count}
    />
  );
};

export default InmatesPage;
