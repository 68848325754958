import { partition } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import {
  ConnectionStatus,
  Entitlement,
  FacilityFeature,
  SystemUserStatus,
} from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import NotAllowed from "@src/lib/NotAllowed";
import NotEnabled from "@src/lib/NotEnabled";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import useFacilityFeature from "@src/lib/useFacilityFeature";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConnectionRequestsTable, {
  ConnectionRequest,
} from "./ConnectionRequestsTable";
import { GetFacilityConnectionRequestsDocument } from "./GetFacilityConnectionRequests.generated";
import ReviewModal from "./ReviewModal";

type Props = {
  showProfessional: boolean;
};

const RequestsPage = ({ showProfessional }: Props) => {
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  const { t } = useTranslation();

  const personalTitle = t("Visitation List Requests");
  const professionalTitle = t("Privileged Contact Requests");
  const personalSubtitle = t(
    "Review all visitation list requests between outside visitors and facility residents. Once the visitor is approved, they will be able to request scheduled meetings."
  );
  const professionalSubtitle = t(
    "Approving a professional to visit means they can schedule meetings and exchange messages with the specified resident."
  );

  const { facility } = useGuaranteedFacilityContext();

  const { loading, data } = useQuery(GetFacilityConnectionRequestsDocument, {
    variables: { id: facility.id, systemId: facility.system.id },
    onError: errorReporter,
    fetchPolicy: "cache-and-network",
  });

  const [connectionToReview, setConnectionToReview] =
    useState<ConnectionRequest>();

  const hasProviders = useFacilityFeature(FacilityFeature.Providers);
  if (showProfessional && !hasProviders) return <NotEnabled />;

  // must match Menu component
  const pendingConnections =
    data?.facility.pendingConnectionRequests.filter(
      (c) =>
        c.status === ConnectionStatus.Pending &&
        c.visitor.systemRelationship?.status !== SystemUserStatus.Rejected
    ) || [];

  const [professionalQueue, personalQueue] = partition(
    pendingConnections,
    (c) => {
      return Boolean(
        facility.features.includes(FacilityFeature.Providers) && c.confidential
      );
    }
  );
  const queue = showProfessional ? professionalQueue : personalQueue;

  // connectionToReview is a static copy and does not respond to
  // apollo cache updates
  const reviewing =
    connectionToReview && queue.find((c) => c.id === connectionToReview.id);

  if (!queue || loading) return <PageLoader />;

  return (
    <>
      <Header
        title={showProfessional ? professionalTitle : personalTitle}
        subtitle={showProfessional ? professionalSubtitle : personalSubtitle}
      />
      {!canManageVisitorsAndMeetings ? (
        <NotAllowed />
      ) : (
        <>
          {reviewing && (
            <ReviewModal
              connection={reviewing}
              onClose={() => setConnectionToReview(undefined)}
            />
          )}
          <Box p={3}>
            <ConnectionRequestsTable
              onReview={setConnectionToReview}
              connections={queue}
              loading={loading}
              showProfessional={showProfessional}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default RequestsPage;
