import { Box } from "@mui/material";
import { Visitor } from "@src/api/graphql";
import { isSubstring } from "@src/lib/Common";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import { searchEachBeginningRegex } from "@src/lib/regex";
import SelectableFieldStringFilter from "@src/lib/SelectableFieldStringFilter";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VisitorTable from "./VisitorTable";

interface Props {
  visitors: Pick<Visitor, "id" | "fullName" | "email">[];
  loading?: boolean;
  totalCount?: number;
}

const Filters = ["fullName", "email"] as const;
type TFilter = typeof Filters[number];

function isFilter(value: unknown): value is TFilter {
  return typeof value === "string" && Filters.includes(value as TFilter);
}

const { history } = window;

export default function Visitors({ visitors, loading, totalCount }: Props) {
  const [filteredVisitors, setFilteredVisitors] = useState<Props["visitors"]>(
    []
  );

  const { t } = useTranslation();

  const FILTER_TO_LABEL_MAP: Record<TFilter, string> = {
    fullName: t("Name"),
    email: t("Email"),
  };

  // search filters are restored from page state for back navigation
  const [searchQuery, setSearchQuery] = useState<string>(
    isObjectWithKey(history.state, "searchQuery") &&
      typeof history.state.searchQuery === "string"
      ? history.state.searchQuery
      : ""
  );
  const [activeSearchFilter, setActiveSearchFilter] = useState<TFilter>(
    isObjectWithKey(history.state, "activeSearchFilter") &&
      isFilter(history.state.activeSearchFilter)
      ? history.state.activeSearchFilter
      : "fullName"
  );
  useEffect(() => {
    history.replaceState({ activeSearchFilter, searchQuery }, "");
  }, [activeSearchFilter, searchQuery]);

  useEffect(() => {
    const regex = searchEachBeginningRegex(searchQuery); // searchEachWordRegex(searchQuery); for searching everywhere

    switch (activeSearchFilter) {
      case "fullName":
        setFilteredVisitors(visitors.filter((v) => regex.test(v.fullName)));
        break;
      case "email":
        setFilteredVisitors(
          visitors.filter((v) => isSubstring(searchQuery, v.email))
        );
        break;
      default:
        setFilteredVisitors(visitors);
        break;
    }
  }, [activeSearchFilter, searchQuery, visitors]);

  return (
    <Box>
      <Header
        title={t("Visitors")}
        subtitle={t(
          "All visitors that have requested contact with a resident of your facility."
        )}
        extra={[
          <SelectableFieldStringFilter
            loading={loading}
            onSelectChange={(value) => setActiveSearchFilter(value as TFilter)}
            onTextChange={(value) => setSearchQuery(value)}
            selected={activeSearchFilter}
            text={searchQuery}
            filterToLabelMap={FILTER_TO_LABEL_MAP}
            key="selectableStringFilter"
          />,
        ]}
      />
      <VisitorTable
        visitors={filteredVisitors}
        loading={loading}
        totalCount={totalCount}
      />
    </Box>
  );
}
