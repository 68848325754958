import { notMissing } from "@ameelio/core";
import { NPSSurvey } from "@ameelio/nps-survey";
import { Button } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  CalendarTodayOutlined,
  PermIdentity,
  SentimentSatisfiedAltOutlined,
} from "@mui/icons-material";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import { AlertTitle, Box, Stack } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingStatus,
  MeetingType,
} from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import errorReporter from "@src/lib/errorReporter";
import ExportScheduleButton from "@src/lib/ExportSchedule";
import Header from "@src/lib/Header";
import PageLoader from "@src/lib/PageLoader";
import ScreenSection from "@src/lib/ScreenSection";
import useBadgeData from "@src/lib/useBadgeData";
import useEntitlement from "@src/lib/useEntitlement";
import { Call } from "@src/lib/useLiveCalls";
import useMountedPolling from "@src/lib/useMountedPolling";
import Tile, { TileSection } from "@src/pages/DashboardPage/DashboardTile";
import { GetDashboardInfoDocument } from "@src/pages/DashboardPage/GetDashboardInfo.generated";
import { SelectedFacility } from "@src/typings/Facility";
import { endOfToday, startOfToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const isStillLive = (call: Call) => new Date(call.interval.endAt) >= new Date();

type TileProps = {
  icon: JSX.Element;
  title: string;
  value: number;
  onClick?: () => void;
};

type TileGroup = {
  title: string;
  tiles: (TileProps | null)[];
};

export default function DashboardNew({
  liveMeetings,
  facility,
}: {
  liveMeetings: Call[];
  facility: SelectedFacility;
}) {
  const facilityHasVideoCalls = facility.features.includes(
    FacilityFeature.VideoCall
  );
  const facilityHasVoiceCalls = facility.features.includes(
    FacilityFeature.VoiceCall
  );
  const facilityHasVisits = facility.features.includes(
    FacilityFeature.InPersonVisit
  );
  const facilityHasWebinars = facility.features.includes(
    FacilityFeature.Webinar
  );
  const facilityHasMessaging = facility.features.includes(
    FacilityFeature.Emessaging
  );

  const facilityHasConfidentialVideoCall = facility.features.includes(
    FacilityFeature.ConfidentialVideoCall
  );
  const facilityHasConfidentialInPersonVisit = facility.features.includes(
    FacilityFeature.ConfidentialInPersonVisit
  );
  const facilityHasProfessionals = facility.features.includes(
    FacilityFeature.Providers
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const reviewCounts = useBadgeData();

  const { data, startPolling, stopPolling } = useQuery(
    GetDashboardInfoDocument,
    {
      variables: {
        facilityId: facility.id,
      },
      fetchPolicy: "cache-and-network",
      onError: errorReporter,
    }
  );
  useMountedPolling(60000, startPolling, stopPolling);

  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  if (!data) return <PageLoader />;

  const {
    scheduledVideoCalls,
    scheduledInPersonVisits,
    scheduledWebinars,
    inmateMetrics,
    visitorMetrics,
    pendingMessages,
  } = data.facility;

  const peopleTileGroup: TileGroup | undefined = {
    title: t("People"),
    tiles: [
      {
        icon: <SentimentSatisfiedAltOutlined />,
        title: t("Visitors"),
        value: visitorMetrics.count,
        onClick: () => navigate("/visitors"),
      },
      {
        icon: <FactCheckOutlinedIcon />,
        title: t("Approvals"),
        value: reviewCounts.count.personalConnections,
        onClick: () => navigate("/connection-requests/personal"),
      },
      facilityHasProfessionals
        ? {
            icon: <FactCheckOutlinedIcon />,
            title: t("Privileged approvals"),
            value: reviewCounts.count.privilegedConnections,
            onClick: () => navigate("/connection-requests/professional"),
          }
        : undefined,
      {
        icon: <PermIdentity />,
        title: t("Residents"),
        value: inmateMetrics.count,
        onClick: () => navigate("/inmates"),
      },
    ].filter(notMissing),
  };

  const videoCallTileGroup: TileGroup | undefined =
    facilityHasVideoCalls || facilityHasConfidentialVideoCall
      ? {
          title: t("Video calls"),
          tiles: [
            {
              icon: <GraphicEqOutlinedIcon />,
              title: t("In progress"),
              value: liveMeetings
                .filter((call) => call.meetingType === MeetingType.VideoCall)
                .filter(isStillLive).length,
              onClick: () => navigate("/live/video-calls"),
            },
            {
              icon: <FactCheckOutlinedIcon />,
              title: t("Approvals"),
              value: reviewCounts.data.personalMeetings.filter(
                (r) => r.meetingType === MeetingType.VideoCall
              ).length,
              onClick: () =>
                navigate("/meeting-requests/personal", {
                  state: {
                    meetingType: MeetingType.VideoCall,
                  },
                }),
            },
            facilityHasConfidentialVideoCall
              ? {
                  icon: <FactCheckOutlinedIcon />,
                  title: t("Privileged approvals"),
                  value: reviewCounts.data.privilegedMeetings.filter(
                    (r) => r.meetingType === MeetingType.VideoCall
                  ).length,
                  onClick: () =>
                    navigate("/meeting-requests/professional", {
                      state: {
                        meetingType: MeetingType.VideoCall,
                      },
                    }),
                }
              : undefined,
            {
              icon: <CalendarTodayOutlined />,
              title: t("Scheduled"),
              value: scheduledVideoCalls.count,
              onClick: () =>
                navigate("/meetings/upcoming", {
                  state: {
                    meetingType: MeetingType.VideoCall,
                    status: [MeetingStatus.Scheduled],
                  },
                }),
            },
          ].filter(notMissing),
        }
      : undefined;

  const inPersonVisitTileGroup: TileGroup | undefined =
    facilityHasVisits || facilityHasConfidentialInPersonVisit
      ? {
          title: t("In-person visits"),
          tiles: [
            {
              icon: <GraphicEqOutlinedIcon />,
              title: t("In progress"),
              value: liveMeetings
                .filter(
                  (call) => call.meetingType === MeetingType.InPersonVisit
                )
                .filter(isStillLive).length,
              onClick: () => {
                navigate("/meetings/upcoming", {
                  state: {
                    meetingType: MeetingType.InPersonVisit,
                    status: [MeetingStatus.Live],
                  },
                });
              },
            },
            {
              icon: <FactCheckOutlinedIcon />,
              title: t("Approvals"),
              value: reviewCounts.data.personalMeetings.filter(
                (r) => r.meetingType === MeetingType.InPersonVisit
              ).length,
              onClick: () =>
                navigate("/meeting-requests/personal", {
                  state: {
                    meetingType: MeetingType.InPersonVisit,
                  },
                }),
            },
            facilityHasConfidentialInPersonVisit
              ? {
                  icon: <FactCheckOutlinedIcon />,
                  title: t("Privileged approvals"),
                  value: reviewCounts.data.privilegedMeetings.filter(
                    (r) => r.meetingType === MeetingType.InPersonVisit
                  ).length,
                  onClick: () =>
                    navigate("/meeting-requests/professional", {
                      state: {
                        meetingType: MeetingType.InPersonVisit,
                      },
                    }),
                }
              : undefined,
            {
              icon: <CalendarTodayOutlined />,
              title: t("Scheduled"),
              value: scheduledInPersonVisits.count,
              onClick: () => {
                navigate("/meetings/upcoming", {
                  state: {
                    meetingType: MeetingType.InPersonVisit,
                    status: [MeetingStatus.Scheduled],
                  },
                });
              },
            },
          ].filter(notMissing),
        }
      : undefined;

  const voiceCallTileGroup: TileGroup | undefined = facilityHasVoiceCalls
    ? {
        title: t("Voice calls"),
        tiles: [
          {
            icon: <GraphicEqOutlinedIcon />,
            title: t("In progress"),
            value: liveMeetings
              .filter((call) => call.meetingType === MeetingType.VoiceCall)
              .filter(isStillLive).length,
            onClick: () => navigate("/live/voice-calls"),
          },
        ],
      }
    : undefined;

  const webinarTileGroup: TileGroup | undefined = facilityHasWebinars
    ? {
        title: t("Webinars"),
        tiles: [
          {
            icon: <GraphicEqOutlinedIcon />,
            title: t("In progress"),
            value: liveMeetings
              .filter((call) => call.meetingType === MeetingType.Webinar)
              .filter(isStillLive).length,
            onClick: () => navigate("/live/webinars"),
          },
          {
            icon: <CalendarTodayOutlined />,
            title: t("Scheduled"),
            value: scheduledWebinars.count,
            onClick: () =>
              navigate("/meetings/upcoming", {
                state: {
                  meetingType: MeetingType.Webinar,
                  status: [MeetingStatus.Scheduled],
                },
              }),
          },
        ],
      }
    : undefined;

  const messagingTileGroup: TileGroup | undefined = facilityHasMessaging
    ? {
        title: t("Messages"),
        tiles: [
          {
            icon: <FactCheckOutlinedIcon />,
            title: t("Requests"),
            value: pendingMessages.count,
            onClick: () => navigate("/message-review"),
          },
        ],
      }
    : undefined;

  const tileGroups: TileGroup[] = [
    peopleTileGroup,
    videoCallTileGroup,
    inPersonVisitTileGroup,
    voiceCallTileGroup,
    webinarTileGroup,
    messagingTileGroup,
  ].filter(notMissing);
  const maxTilesPerRow = tileGroups.reduce((a, b) => {
    return b.tiles.length > a ? b.tiles.length : a;
  }, 0);

  return (
    <>
      <Header
        title={t("Dashboard")}
        subtitle=""
        breadcrumb={
          <Breadcrumbs
            paths={[
              { label: facility.system.code, path: "/system" },
              { label: facility.publicId, path: "/" },
            ]}
          />
        }
      />
      <Alert severity="info" sx={{ m: 3 }}>
        <AlertTitle>{t("New home page")}</AlertTitle>
        {t(
          "Welcome to the updated home page for Ameelio Connect's Staff Dashboard. We've worked to make this home page more useful for everyone, with shortcuts for many common tasks. Have an idea to make it better? Please let us know at "
        )}
        <a
          href="mailto:facilitysupport@ameelio.org"
          target="_blank"
          rel="noreferrer"
        >
          facilitysupport@ameelio.org
        </a>
        .
      </Alert>
      <Stack
        direction={{
          xs: "column",
          lg: "row",
        }}
        spacing={2}
        margin={3}
      >
        <Stack
          flex={1}
          spacing={2}
          order={{
            xs: 1,
            lg: 0,
          }}
        >
          {tileGroups.map((group) => (
            <TileSection
              key={group.title}
              title={group.title}
              tileCount={maxTilesPerRow}
            >
              {group.tiles.map(
                (tile) =>
                  tile && (
                    <Tile
                      key={tile.title}
                      icon={tile.icon}
                      title={tile.title}
                      value={tile.value}
                      onClick={tile.onClick}
                    />
                  )
              )}
            </TileSection>
          ))}
        </Stack>
        <Box
          display={{
            xs: "none",
            lg: "block",
          }}
          order={{
            xs: 0,
            lg: 1,
          }}
        >
          <ScreenSection
            sx={{
              width: {
                xs: "100%",
                lg: "300px",
                border: "none",
              },
              mb: 2,
            }}
            title={t("Quick actions")}
            titleProps={{
              variant: "h2",
            }}
          >
            <Stack spacing={2}>
              <Button
                variant="outlined"
                disabled={!canManageVisitorsAndMeetings}
                disabledReason={t(
                  "You do not have permission to schedule meetings"
                )}
                key="schedule-meeting"
                onClick={() => navigate("/inmates/event/new")}
              >
                {t("Schedule meeting")}
              </Button>
              {facilityHasVideoCalls || facilityHasVisits
                ? [
                    <ExportScheduleButton
                      facility={facility}
                      key="export-schedule"
                      filters={{
                        meetingType: [
                          MeetingType.VideoCall,
                          MeetingType.InPersonVisit,
                        ],
                        meetingStatus: [
                          MeetingStatus.Scheduled,
                          MeetingStatus.Live,
                          MeetingStatus.Ended,
                          MeetingStatus.Terminated,
                          MeetingStatus.NoShow,
                        ],
                        scheduledStartAfter: startOfToday().getTime(),
                        scheduledStartBefore: endOfToday().getTime(),
                      }}
                    >
                      {t("Download schedule")}
                    </ExportScheduleButton>,
                  ]
                : undefined}
            </Stack>
          </ScreenSection>
        </Box>
      </Stack>
      {facility.country === "US" && <NPSSurvey userType="Staff" />}
    </>
  );
}
