import { Group, Inmate } from "@src/api/graphql";
import { isSubstring } from "@src/lib/Common";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import { searchEachBeginningRegex } from "@src/lib/regex";
import SelectableFieldStringFilter from "@src/lib/SelectableFieldStringFilter";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InmateTable from "./InmateTable";

interface Props {
  inmates: (Pick<Inmate, "id" | "fullName" | "inmateIdentification"> & {
    group: Pick<Group, "name">;
  })[];
  loading?: boolean;
  totalCount?: number;
}

const Filters = ["fullName", "inmateIdentification"] as const;
type TFilter = typeof Filters[number];

function isFilter(value: unknown): value is TFilter {
  return typeof value === "string" && Filters.includes(value as TFilter);
}

const { history } = window;

export default function Inmates({ inmates, loading, totalCount }: Props) {
  const [filteredInmates, setFilteredInmates] = useState<Props["inmates"]>([]);

  const { t } = useTranslation();

  const FILTER_TO_LABEL_MAP: Record<TFilter, string> = {
    fullName: t("Name"),
    inmateIdentification: t("Unique ID"),
  };

  // search filters are restored from page state for back navigation
  const [searchQuery, setSearchQuery] = useState<string>(
    isObjectWithKey(history.state, "searchQuery") &&
      typeof history.state.searchQuery === "string"
      ? history.state.searchQuery
      : ""
  );
  const [activeSearchFilter, setActiveSearchFilter] = useState<TFilter>(
    isObjectWithKey(history.state, "activeSearchFilter") &&
      isFilter(history.state.activeSearchFilter)
      ? history.state.activeSearchFilter
      : "fullName"
  );
  useEffect(() => {
    history.replaceState({ activeSearchFilter, searchQuery }, "");
  }, [activeSearchFilter, searchQuery]);

  useEffect(() => {
    const regex = searchEachBeginningRegex(searchQuery); // searchEachWordRegex(searchQuery); for searching everywhere

    switch (activeSearchFilter) {
      case "fullName":
        setFilteredInmates(inmates.filter((i) => regex.test(i.fullName)));
        break;
      case "inmateIdentification":
        setFilteredInmates(
          inmates.filter((i) =>
            isSubstring(searchQuery, i.inmateIdentification)
          )
        );
        break;
      default:
        setFilteredInmates(inmates);
        break;
    }
  }, [activeSearchFilter, searchQuery, inmates]);

  return (
    <>
      <Header
        title={t("Residents")}
        subtitle={t(
          "Manage the residents of your facility, access detailed information, and edit their info as needed."
        )}
        extra={[
          <SelectableFieldStringFilter
            loading={loading}
            onSelectChange={(value) => setActiveSearchFilter(value as TFilter)}
            onTextChange={(value) => setSearchQuery(value)}
            selected={activeSearchFilter}
            text={searchQuery}
            filterToLabelMap={FILTER_TO_LABEL_MAP}
            key="selectableStringFilter"
          />,
        ]}
      />
      <InmateTable
        inmates={filteredInmates}
        loading={loading}
        totalCount={totalCount}
      />
    </>
  );
}
