const features = [
  "TEST_CALLS",
  "LANGUAGE_SETTINGS",
  "DAILY_SCHEDULE",
  "NEW_ACTIVITY_PAGE",
] as const;
type Feature = typeof features[number];

export default function featureEnabled(name: Feature): boolean {
  const feature = import.meta.env[`VITE_ENABLE_${name}`];
  // Force the new activity page to be enabled.
  if (name === "NEW_ACTIVITY_PAGE") {
    return true;
  }
  // If there was a string value provided, it MUST be 'true' when lowered and trimmed
  return feature ? feature.toLowerCase().trim() === "true" : !!feature;
}
