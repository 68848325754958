import {
  Button,
  ButtonProps,
  ConfirmDialog,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Meeting } from "@src/api/graphql";
import ContextMenuItem from "@src/lib/ContextMenuItem";
import errorReporter from "@src/lib/errorReporter";
import { SetMeetingNoShowDocument } from "@src/pages/LiveCallPage/SetMeetingNoShow.generated";
import { useTranslation } from "react-i18next";
import useBoolean from "./useBoolean";

type Props = ButtonProps & {
  meeting: Pick<Meeting, "id" | "status">;
  displayAsMenuItem?: boolean;
};

export default function NoShowMeetingButton({
  meeting,
  displayAsMenuItem,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const noShowMeetingDialog = useBoolean();

  const [setMeetingNoShow, { loading: submitting }] = useMutation(
    SetMeetingNoShowDocument,
    {
      onError: errorReporter,
      onCompleted: () => {
        snackbarContext.alert(
          "success",
          t("The meeting has been marked as unattended.")
        );
      },
    }
  );

  async function updateToNoShow() {
    await setMeetingNoShow({
      variables: {
        input: {
          meetingId: meeting.id,
          reason: t("Meeting marked as unattended by facility staff."),
        },
      },
    });
  }

  return (
    <>
      {noShowMeetingDialog.current && (
        <ConfirmDialog
          variant="remove"
          description={t(
            "Please confirm that the meeting was unattended and did not take place."
          )}
          onCancel={noShowMeetingDialog.disable}
          confirmText={t("Yes, confirm")}
          onConfirm={async () => {
            // Invoke the callback
            await updateToNoShow();
            // Then hide the dialog
            noShowMeetingDialog.disable();
          }}
        />
      )}
      {displayAsMenuItem ? (
        <ContextMenuItem
          key="set-meeting-no-show"
          disabled={submitting}
          color="error"
          onClick={noShowMeetingDialog.enable}
        >
          {t("Mark as unattended")}
        </ContextMenuItem>
      ) : (
        <Button
          color="error"
          variant="outlined"
          disabled={submitting}
          onClick={noShowMeetingDialog.enable}
          {...rest}
        >
          {t("Mark as unattended")}
        </Button>
      )}
    </>
  );
}
