import { Button, MultiSelectChipInputBase } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Entitlement } from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import { isSubstring } from "@src/lib/Common";
import entitlementLabel from "@src/lib/entitlementLabel";
import Header from "@src/lib/Header";
import SelectableFieldStringFilter from "@src/lib/SelectableFieldStringFilter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AddStaffModal from "./AddEditStaffModal";
import { GetSystemStaffDocument } from "./GetSystemStaff.generated";
import StaffTable from "./StaffTable";

const ENTITLEMENT_FILTER_OPTIONS: {
  value: string;
  label: string;
}[] = Object.values(Entitlement).map((e) => ({
  label: entitlementLabel(e),
  value: e,
}));

type Filters = {
  facilities: string[];
  entitlements: Entitlement[];
  activeQueryField: "fullName";
  queryString: string;
};

export default function SystemStaffPage() {
  const { system } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const canManageStaff = useEntitlement(Entitlement.ManageStaff);

  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);

  const [filters, setFilters] = useState<Filters>({
    facilities: [],
    entitlements: [],
    activeQueryField: "fullName",
    queryString: "",
  });
  const FILTER_TO_LABEL_MAP: Record<Filters["activeQueryField"], string> = {
    fullName: t("Name"),
  };

  const { data, error } = useQuery(GetSystemStaffDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      systemId: system.id,
    },
  });
  if (error) throw error;

  const deactivatedStaff = data
    ? data.system.staff.filter((s) => s.staffPositions.length === 0)
    : [];

  const filteredStaff = useMemo(
    () =>
      (data ? data.system.staff.filter((s) => s.staffPositions.length > 0) : [])
        .filter(
          (s) =>
            filters.entitlements.length === 0 ||
            filters.entitlements.some((e) =>
              s.staffEntitlements.some((se) => se.entitlement === e)
            )
        )
        .filter(
          (s) =>
            filters.facilities.length === 0 ||
            filters.facilities.some((fId) =>
              s.staffPositions.some((sp) => sp.facility.id === fId)
            )
        )
        .filter(
          (s) =>
            filters.queryString.length === 0 ||
            isSubstring(filters.queryString, s[filters.activeQueryField])
        ),
    [data, filters]
  );

  return (
    <>
      <Header
        title={t("Staff")}
        breadcrumb={
          <Breadcrumbs
            paths={[
              {
                path: "/system",
                label: system.code,
              },
              {
                path: "/system",
                label: t("Staff"),
              },
            ]}
          />
        }
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
          gap={2}
        >
          <SelectableFieldStringFilter
            key="selectableStringFilter"
            loading={!data}
            onSelectChange={(value) =>
              setFilters((current) => ({
                ...current,
                activeQueryField: value as Filters["activeQueryField"],
                queryString: "",
              }))
            }
            onTextChange={(value) =>
              setFilters((current) => ({
                ...current,
                queryString: value,
              }))
            }
            selected={filters.activeQueryField}
            text={filters.queryString}
            filterToLabelMap={FILTER_TO_LABEL_MAP}
          />
          {data ? (
            <Box sx={{ width: 250, flexShrink: 0 }}>
              <MultiSelectChipInputBase
                size="small"
                sx={{ maxWidth: 250 }}
                label={t("Facilities")}
                value={filters.facilities || []}
                items={data.system.facilities.map((f) => ({
                  name: f.publicId,
                  value: f.id,
                }))}
                onChange={(event) => {
                  setFilters((current) => ({
                    ...current,
                    facilities: event.target.value as string[],
                  }));
                }}
              />
            </Box>
          ) : null}
          {data ? (
            <Box sx={{ width: 250, flexShrink: 0 }}>
              <MultiSelectChipInputBase
                size="small"
                sx={{ maxWidth: 250 }}
                label={t("Entitlements")}
                value={filters.entitlements || []}
                items={ENTITLEMENT_FILTER_OPTIONS.map((f) => ({
                  name: f.label,
                  value: f.value,
                }))}
                onChange={(event) => {
                  setFilters((current) => ({
                    ...current,
                    entitlements: event.target.value as Entitlement[],
                  }));
                }}
              />
            </Box>
          ) : null}
          <Button
            key="add-staff"
            variant="contained"
            disabled={!canManageStaff}
            disabledReason={t(
              "You do not have permission to perform this function."
            )}
            onClick={() => setIsAddStaffModalOpen(true)}
            sx={{ flexShrink: 0 }}
          >
            {t("Add Staff")}
          </Button>
        </Box>
      </Header>
      <Box p={3}>
        <StaffTable
          loading={!data}
          staff={filteredStaff}
          deactivatedStaff={deactivatedStaff}
          filters={filters}
        />
      </Box>
      {isAddStaffModalOpen && (
        <AddStaffModal
          onClose={() => setIsAddStaffModalOpen(false)}
          systemId={system.id}
        />
      )}
    </>
  );
}
