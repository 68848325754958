import { useQuery } from "@apollo/client";
import {
  GroupsOutlined,
  PhoneOutlined,
  PieChartOutline,
  SafetyDivider,
  VideoCameraFrontOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { Box, Card, Stack, Tab, Tabs } from "@mui/material";
import { FacilityFeature, MeetingStatus, MeetingType } from "@src/api/graphql";
import { mondayMorning } from "@src/lib/Call";
import errorReporter from "@src/lib/errorReporter";
import ExportScheduleButton from "@src/lib/ExportSchedule";
import Header from "@src/lib/Header";
import HorizontalBarChart from "@src/lib/HorizontalBarChart";
import Info from "@src/lib/Info";
import LineChart from "@src/lib/LineChart";
import PageLoader from "@src/lib/PageLoader";
import { shortMonthDate } from "@src/lib/Time";
import { SectionTitle } from "@src/lib/typography";
import { Call } from "@src/lib/useLiveCalls";
import { SelectedFacility } from "@src/typings/Facility";
import {
  eachDayOfInterval,
  endOfToday,
  format,
  startOfToday,
  subWeeks,
} from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardMetric, { DashboardMetricRow } from "./DashboardMetric";
import { GetFacilityMetricsDocument } from "./GetFacilityMetrics.generated";

interface Props {
  calls: Call[];
  facility: SelectedFacility;
}

function countsInInterval(
  interval: { start: Date; end: Date },
  data: { group: string; count: number }[]
): Record<string, number> {
  const byDay = data.reduce<Record<string, number>>(
    (memo, i) => ({ ...memo, [i.group]: i.count }),
    {}
  );
  const eachDay: [string, number][] = eachDayOfInterval(interval).map((d) => {
    return [shortMonthDate(d), byDay[format(d, "yyyy-MM-dd")] || 0];
  });
  return Object.fromEntries(eachDay);
}

const ChartTabs = ["videoCalls", "visits", "voiceCalls"] as const;
type ChartTab = typeof ChartTabs[number];

const Activity: React.FC<Props> = ({ calls, facility }) => {
  const facilityHasVideoCalls = facility.features.includes(
    FacilityFeature.VideoCall
  );
  const facilityHasVoiceCalls = facility.features.includes(
    FacilityFeature.VoiceCall
  );
  const facilityHasVisits = facility.features.includes(
    FacilityFeature.InPersonVisit
  );

  const [eventsChartTab, setEventsChartTab] = useState<ChartTab>(() => {
    if (facilityHasVideoCalls) {
      return "videoCalls";
    } else if (facilityHasVoiceCalls) {
      return "voiceCalls";
    } else {
      return "visits";
    }
  });
  const [ratingsChartTab, setRatingsChartTab] =
    useState<ChartTab>("videoCalls");

  const { t } = useTranslation();

  const startOfWeek = mondayMorning(new Date());
  const reportStart = subWeeks(startOfWeek, 4);

  const { data, loading } = useQuery(GetFacilityMetricsDocument, {
    variables: {
      reportStart: reportStart.getTime(),
      thisWeek: startOfWeek.getTime(),
      facilityId: facility.id,
    },
    onError: errorReporter,
  });

  if (loading || !data) return <PageLoader />;

  const {
    videoCallMetrics,
    videoCallMetricsThisWeek,
    voiceCallMetrics,
    voiceCallMetricsThisWeek,
    visitMetrics,
    visitMetricsThisWeek,
    inmateMetrics,
  } = data.facility;

  const videoCallRatings = [1, 2, 3, 4, 5]
    .map(
      (n) =>
        videoCallMetrics.ratings.find(
          (r) => r.group.toString() === n.toString()
        )?.count || 0
    )
    .reverse();
  const voiceCallRatings = [1, 2, 3, 4, 5]
    .map(
      (n) =>
        voiceCallMetrics.ratings.find(
          (r) => r.group.toString() === n.toString()
        )?.count || 0
    )
    .reverse();

  const interval = { start: reportStart, end: startOfWeek };
  const videoCallsVolume = countsInInterval(
    interval,
    videoCallMetrics.countByDay
  );
  const voiceCallsVolume = countsInInterval(
    interval,
    voiceCallMetrics.countByDay
  );
  const visitVolume = countsInInterval(interval, visitMetrics.countByDay);

  return (
    <>
      <Header
        title={t("Dashboard")}
        subtitle={t("Your facility at a glance.")}
        extra={
          facilityHasVideoCalls || facilityHasVisits
            ? [
                <ExportScheduleButton
                  facility={facility}
                  filters={{
                    meetingType: [MeetingType.VideoCall],
                    meetingStatus: [
                      MeetingStatus.Scheduled,
                      MeetingStatus.Live,
                      MeetingStatus.Ended,
                      MeetingStatus.Terminated,
                      MeetingStatus.NoShow,
                    ],
                    scheduledStartAfter: startOfToday().getTime(),
                    scheduledStartBefore: endOfToday().getTime(),
                  }}
                >
                  {t("Download Schedule")}
                </ExportScheduleButton>,
              ]
            : undefined
        }
      />
      <Stack spacing={3} margin={3}>
        {facilityHasVideoCalls && (
          <Card variant="outlined" sx={{ padding: 3 }}>
            <Box mb={2}>
              <SectionTitle>{t("Video calls")}</SectionTitle>
            </Box>

            <DashboardMetricRow>
              <DashboardMetric
                label={t("This week")}
                icon={<VideocamOutlined />}
                value={t("{{count}} calls", {
                  count: videoCallMetricsThisWeek.count,
                })}
              />
              <DashboardMetric
                label={t("Live now")}
                linkTo="/live/video-calls"
                icon={<VideoCameraFrontOutlined />}
                value={t("{{count}} calls", {
                  count: calls.filter(
                    (call) => new Date(call.interval.endAt) >= new Date()
                  ).length,
                })}
              />{" "}
              <DashboardMetric
                label={t("Adoption rate")}
                explanation={t(
                  "Percentage of current residents participating in video calls over the most recent four complete calendar weeks."
                )}
                icon={<PieChartOutline />}
                value={t("{{percentage}}%", {
                  percentage: Math.round(
                    (videoCallMetrics.uniqueInmates / inmateMetrics.count) * 100
                  ),
                })}
              />
            </DashboardMetricRow>
          </Card>
        )}
        {facilityHasVisits && (
          <Card variant="outlined" sx={{ padding: 3 }}>
            <Box mb={2}>
              <SectionTitle>{t("In-person visits")}</SectionTitle>
            </Box>

            <DashboardMetricRow>
              <DashboardMetric
                label={t("This week")}
                icon={<SafetyDivider />}
                value={t("{{count}} visits", {
                  count: visitMetricsThisWeek.count,
                })}
              />
              <DashboardMetric
                label={t("Visitors this week")}
                icon={<GroupsOutlined />}
                value={t("{{count}} visitors", {
                  count: visitMetricsThisWeek.uniqueVisitors,
                })}
              />
              <DashboardMetric
                label={t("Adoption rate")}
                explanation={t(
                  "Percentage of current residents participating in in-person visits over the most recent four complete calendar weeks."
                )}
                icon={<PieChartOutline />}
                value={t("{{percentage}}%", {
                  percentage: Math.round(
                    (visitMetrics.uniqueInmates / inmateMetrics.count) * 100
                  ),
                })}
              />
            </DashboardMetricRow>
          </Card>
        )}
        {facilityHasVoiceCalls && (
          <Card variant="outlined" sx={{ padding: 3 }}>
            <Box mb={2}>
              <SectionTitle>{t("Voice calls")}</SectionTitle>
            </Box>

            <DashboardMetricRow>
              <DashboardMetric
                label={t("This week")}
                icon={<PhoneOutlined />}
                value={t("{{count}} calls", {
                  count: voiceCallMetricsThisWeek.count,
                })}
              />
              <DashboardMetric
                label={t("Visitors contacted this week")}
                icon={<GroupsOutlined />}
                value={t("{{count}} visitors", {
                  count: voiceCallMetricsThisWeek.uniqueVisitors,
                })}
              />
              <DashboardMetric
                label={t("Adoption rate")}
                explanation={t(
                  "Percentage of current residents participating in voice calls over the most recent four complete calendar weeks."
                )}
                icon={<PieChartOutline />}
                value={t("{{percentage}}%", {
                  percentage: Math.round(
                    (voiceCallMetrics.uniqueInmates / inmateMetrics.count) * 100
                  ),
                })}
              />
            </DashboardMetricRow>
          </Card>
        )}
        <Stack
          spacing={3}
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={{
            maxWidth: {
              xs: "100%",
              md:
                facilityHasVoiceCalls || facilityHasVideoCalls ? "100%" : "50%",
            },
          }}
        >
          <Card variant="outlined" sx={{ p: 3, flex: 1 }}>
            <Box mb={2}>
              <SectionTitle>{t("Events")}</SectionTitle>
            </Box>
            <Tabs
              value={eventsChartTab}
              onChange={(_, value) => setEventsChartTab(value)}
            >
              {facilityHasVideoCalls && (
                <Tab label={t("Video calls")} value="videoCalls" />
              )}
              {facilityHasVoiceCalls && (
                <Tab label={t("Voice calls")} value="voiceCalls" />
              )}
              {facilityHasVisits && <Tab label={t("Visits")} value="visits" />}
            </Tabs>
            <Box width={1}>
              {eventsChartTab === "videoCalls" ? (
                <LineChart
                  label={t("# calls")}
                  labels={Object.keys(videoCallsVolume)}
                  data={Object.values(videoCallsVolume)}
                />
              ) : eventsChartTab === "voiceCalls" ? (
                <LineChart
                  label={t("# calls")}
                  labels={Object.keys(voiceCallsVolume)}
                  data={Object.values(voiceCallsVolume)}
                />
              ) : (
                <LineChart
                  label={t("# visits")}
                  labels={Object.keys(visitVolume)}
                  data={Object.values(visitVolume)}
                />
              )}
            </Box>
          </Card>

          {(facilityHasVoiceCalls || facilityHasVideoCalls) && (
            <Card variant="outlined" sx={{ p: 3, flex: 1 }}>
              <Box mb={2}>
                <SectionTitle>
                  {t("Call ratings")}
                  <Info
                    message={t(
                      "Ratings are collected from call participants and are reported over the most recent four complete calendar weeks."
                    )}
                  />
                </SectionTitle>
              </Box>
              <Tabs
                value={ratingsChartTab}
                onChange={(_, value) => setRatingsChartTab(value)}
              >
                {facilityHasVideoCalls && (
                  <Tab label={t("Video calls")} value="videoCalls" />
                )}
                {facilityHasVoiceCalls && (
                  <Tab label={t("Voice calls")} value="voiceCalls" />
                )}
              </Tabs>
              {ratingsChartTab === "videoCalls" ? (
                <HorizontalBarChart
                  data={videoCallRatings}
                  labels={[
                    t("Excellent"),
                    t("Great"),
                    t("Good"),
                    t("Bad"),
                    t("Terrible"),
                  ]}
                />
              ) : (
                <HorizontalBarChart
                  data={voiceCallRatings}
                  labels={[
                    t("Excellent"),
                    t("Great"),
                    t("Good"),
                    t("Bad"),
                    t("Terrible"),
                  ]}
                />
              )}
            </Card>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Activity;
