import {
  AccessTimeFilledOutlined,
  AccessTimeOutlined,
  CalendarMonth,
  CalendarMonthOutlined,
  CalendarToday,
  CalendarTodayOutlined,
  Chat,
  ChatOutlined,
  Computer,
  ComputerOutlined,
  Group,
  GroupOutlined,
  History,
  HistoryOutlined,
  ListAlt,
  ListAltOutlined,
  MapsHomeWork,
  MapsHomeWorkOutlined,
  PhoneEnabled,
  PhoneEnabledOutlined,
  PresentToAll,
  PresentToAllOutlined,
  Router,
  RouterOutlined,
  Videocam,
  VideocamOutlined,
} from "@mui/icons-material";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import PresentToAllOutlinedIcon from "@mui/icons-material/PresentToAllOutlined";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Entitlement, FacilityFeature, MeetingType } from "@src/api/graphql";
import featureEnabled from "@src/lib/featureEnabled";
import hasScheduling from "@src/lib/hasScheduling";
import {
  MenuGroup,
  MenuLink,
  MenuList,
  MenuPaper,
} from "@src/lib/NavigationMenus";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useBadgeData from "@src/lib/useBadgeData";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useLiveCalls from "@src/lib/useLiveCalls";
import { MenuTab } from "@src/pages/routes";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";
import CornerMenu from "./CornerMenu";

export default function FacilityMenu({
  selectedTab,
  onClick,
}: {
  selectedTab: MenuTab;
  onClick?: () => void;
}) {
  const { facility: selectedFacility, selectFacility } =
    useGuaranteedFacilityContext();
  const facilityFeatures = selectedFacility.features;

  const user = useCurrentStaff();
  const entitlements = user.staffEntitlements
    .filter((se) => se.isActive)
    .map((se) => se.entitlement);

  const { t } = useTranslation();

  const liveCalls = useLiveCalls({
    facility: selectedFacility,
  });

  const reviewCounts = useBadgeData();

  const canAccessDailySchedule =
    hasScheduling(facilityFeatures) &&
    (selectedFacility.country === "PK" || featureEnabled("DAILY_SCHEDULE"));

  return (
    <MenuPaper>
      <CornerMenu onSelectFacility={(f) => selectFacility(f)}>
        <Box
          m={2}
          mr={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Avatar
            fallback={selectedFacility.publicId}
            showFull
            style={{ fontSize: "inherit" }}
          />
          <Box ml={1} sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="h3"
              color="common.white"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              component="p"
            >
              {selectedFacility.name}
            </Typography>
            {/* dark.text.secondary */}
            <Typography variant="body1" color="rgba(255,255,255,0.70)">
              {user.fullName}
            </Typography>
          </Box>
          <KeyboardArrowDown sx={{ color: "common.white" }} fontSize="small" />
        </Box>
      </CornerMenu>

      <MenuList>
        <MenuLink
          key="action-dashboard"
          icon={<DashboardOutlinedIcon fontSize="small" />}
          selectedIcon={<DashboardIcon fontSize="small" />}
          path="/"
          tab="dashboard"
          selectedTab={selectedTab}
          onClick={onClick}
        >
          {t("Dashboard")}
        </MenuLink>
        {[
          FacilityFeature.VideoCall,
          FacilityFeature.VoiceCall,
          FacilityFeature.Webinar,
        ].some((f) => facilityFeatures.includes(f)) ? (
          <MenuGroup
            icon={<GraphicEqOutlinedIcon fontSize="small" />}
            selectedIcon={<GraphicEqIcon fontSize="small" />}
            label={t("In progress")}
            containedTabs={[
              "live-video-calls",
              "live-voice-calls",
              "live-webinars",
            ]}
            selectedTab={selectedTab}
            showBelow
            badgeCount={
              liveCalls.filter(
                (r) => r.meetingType !== MeetingType.InPersonVisit
              ).length
            }
          >
            {facilityFeatures.includes(FacilityFeature.VideoCall) && (
              <MenuLink
                key="action-live-video-calls"
                submenu
                icon={<VideocamOutlinedIcon fontSize="small" />}
                selectedIcon={<VideocamIcon fontSize="small" />}
                path="/live/video-calls"
                tab="live-video-calls"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.MonitorLiveCalls)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={
                  liveCalls.filter(
                    (c) => c.meetingType === MeetingType.VideoCall
                  ).length
                }
                onClick={onClick}
              >
                {t("Video calls")}
              </MenuLink>
            )}
            {facilityFeatures.includes(FacilityFeature.VoiceCall) && (
              <MenuLink
                key="action-live-voice-calls"
                submenu
                icon={<PhoneInTalkOutlinedIcon fontSize="small" />}
                selectedIcon={<PhoneInTalkIcon fontSize="small" />}
                path="/live/voice-calls"
                tab="live-voice-calls"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.MonitorLiveCalls)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={
                  liveCalls.filter(
                    (c) => c.meetingType === MeetingType.VoiceCall
                  ).length
                }
                onClick={onClick}
              >
                {t("Voice calls")}
              </MenuLink>
            )}
            {facilityFeatures.includes(FacilityFeature.Webinar) && (
              <MenuLink
                key="action-live-webinars"
                submenu
                icon={<PresentToAllOutlinedIcon fontSize="small" />}
                selectedIcon={<PresentToAllIcon fontSize="small" />}
                path="/live/webinars"
                tab="live-webinars"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.MonitorLiveCalls)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={
                  liveCalls.filter((c) => c.meetingType === MeetingType.Webinar)
                    .length
                }
                onClick={onClick}
              >
                {t("Education webinars")}
              </MenuLink>
            )}
          </MenuGroup>
        ) : null}
        <MenuGroup
          icon={<AccessTimeOutlined fontSize="small" />}
          selectedIcon={<AccessTimeFilledOutlined fontSize="small" />}
          label={t("Meetings")}
          containedTabs={[
            "meetings-upcoming",
            "meetings-history",
            "meetings-daily",
          ]}
          selectedTab={selectedTab}
          showBelow
        >
          {hasScheduling(facilityFeatures) && (
            <MenuLink
              submenu
              key="action-meetings-upcoming"
              icon={<CalendarMonthOutlined fontSize="small" />}
              selectedIcon={<CalendarMonth fontSize="small" />}
              path="/meetings/upcoming"
              tab="meetings-upcoming"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Upcoming meetings")}
            </MenuLink>
          )}
          {canAccessDailySchedule && (
            <MenuLink
              submenu
              key="action-meetings-daily"
              icon={<CalendarTodayOutlined fontSize="small" />}
              selectedIcon={<CalendarToday fontSize="small" />}
              path="/meetings/daily"
              tab="meetings-daily"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Daily schedule")}
            </MenuLink>
          )}
          <MenuLink
            submenu
            key="action-meetings-history"
            icon={<HistoryOutlined fontSize="small" />}
            selectedIcon={<History fontSize="small" />}
            path="/meetings/history"
            tab="meetings-history"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("History")}
          </MenuLink>
        </MenuGroup>
        <MenuGroup
          icon={<FactCheckOutlinedIcon fontSize="small" />}
          selectedIcon={<FactCheckIcon fontSize="small" />}
          label={t("Approvals")}
          containedTabs={[
            "visitor-meeting-requests",
            "visitor-connection-requests",
            "visitor-pending-messages",
            "professional-meeting-requests",
            "professional-connection-requests",
          ]}
          selectedTab={selectedTab}
          badgeCount={Object.values(reviewCounts.count).reduce(
            (a, b) => a + b,
            0
          )}
          showBelow
        >
          <MenuLink
            submenu
            icon={<AddReactionOutlinedIcon fontSize="small" />}
            selectedIcon={<AddReactionIcon fontSize="small" />}
            path="/connection-requests/personal"
            tab="visitor-connection-requests"
            selectedTab={selectedTab}
            disabledReason={
              !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                ? t("You do not have permission to use this")
                : undefined
            }
            badgeCount={reviewCounts.count.personalConnections}
            onClick={onClick}
          >
            {t("Visitor contacts")}
          </MenuLink>
          {(facilityFeatures.includes(FacilityFeature.VideoCall) ||
            facilityFeatures.includes(FacilityFeature.InPersonVisit)) && (
            <MenuLink
              submenu
              icon={<EventAvailableOutlinedIcon fontSize="small" />}
              selectedIcon={<EventAvailableIcon fontSize="small" />}
              path="/meeting-requests/personal"
              tab="visitor-meeting-requests"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={reviewCounts.count.personalMeetings}
              onClick={onClick}
            >
              {t("Visitor meetings")}
            </MenuLink>
          )}
          {facilityFeatures.includes(FacilityFeature.Emessaging) && (
            <MenuLink
              submenu
              icon={<MarkChatReadOutlinedIcon fontSize="small" />}
              selectedIcon={<MarkChatReadIcon fontSize="small" />}
              path="/message-review"
              tab="visitor-pending-messages"
              selectedTab={selectedTab}
              disabledReason={
                !entitlements.includes(Entitlement.ReviewEmessages)
                  ? t("You do not have permission to use this")
                  : undefined
              }
              badgeCount={reviewCounts.count.messages}
              onClick={onClick}
            >
              {t("Pending messages")}
            </MenuLink>
          )}
          {facilityFeatures.includes(FacilityFeature.ConfidentialVideoCall) && (
            <Box>
              <MenuLink
                submenu
                icon={<PersonAddAltOutlinedIcon fontSize="small" />}
                selectedIcon={<PersonAddAlt1Icon fontSize="small" />}
                path="/connection-requests/professional"
                tab="professional-connection-requests"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={reviewCounts.count.privilegedConnections}
                onClick={onClick}
              >
                {t("Privileged contacts")}
              </MenuLink>
              <MenuLink
                submenu
                icon={<EventAvailableOutlinedIcon fontSize="small" />}
                selectedIcon={<EventAvailableIcon fontSize="small" />}
                path="/meeting-requests/professional"
                tab="professional-meeting-requests"
                selectedTab={selectedTab}
                disabledReason={
                  !entitlements.includes(Entitlement.ManageVisitorsAndMeetings)
                    ? t("You do not have permission to use this")
                    : undefined
                }
                badgeCount={reviewCounts.count.privilegedMeetings}
                onClick={onClick}
              >
                {t("Privileged meetings")}
              </MenuLink>
            </Box>
          )}
        </MenuGroup>
        <MenuGroup
          icon={<ListAltIcon fontSize="small" />}
          selectedIcon={<ListAltIcon fontSize="small" />}
          label={t("Directory")}
          containedTabs={["inmates", "visitors", "professional-partners"]}
          selectedTab={selectedTab}
          showBelow
        >
          <MenuLink
            key="action-inmates"
            submenu
            icon={<PersonOutlineOutlinedIcon fontSize="small" />}
            selectedIcon={<PersonIcon fontSize="small" />}
            path="/inmates"
            tab="inmates"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Residents")}
          </MenuLink>
          <MenuLink
            key="action-visitors"
            submenu
            icon={<SentimentSatisfiedAltIcon fontSize="small" />}
            selectedIcon={<SentimentSatisfiedAltIcon fontSize="small" />}
            path="/visitors"
            tab="visitors"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Visitors")}
          </MenuLink>
          {facilityFeatures.includes(FacilityFeature.Providers) && (
            <MenuLink
              key="action-professional-partners"
              submenu
              icon={<BusinessIcon fontSize="small" />}
              selectedIcon={<BusinessIcon fontSize="small" />}
              path="/organizations"
              tab="professional-partners"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Organizations")}
            </MenuLink>
          )}
        </MenuGroup>
        <MenuGroup
          icon={<SettingsOutlinedIcon fontSize="small" />}
          selectedIcon={<SettingsIcon fontSize="small" />}
          label={t("Facility administration")}
          containedTabs={[
            "activity",
            "settings-facility",
            "settings-kiosks",
            "settings-phone-calls",
            "settings-network",
            "settings-confidential-in-person-visits",
            "settings-in-person-visits",
            "settings-video-calls",
            "settings-confidential-video-calls",
            "settings-webinars",
            "settings-voice-calls",
            "settings-messages",
          ]}
          selectedTab={selectedTab}
          showBelow
        >
          <MenuLink
            submenu
            key="action-activity"
            icon={<DashboardOutlinedIcon fontSize="small" />}
            selectedIcon={<DashboardIcon fontSize="small" />}
            path="/activity"
            tab="activity"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Activity overview")}
          </MenuLink>
          <MenuLink
            submenu
            key="action-settings-facility"
            icon={<MapsHomeWorkOutlined fontSize="small" />}
            selectedIcon={<MapsHomeWork fontSize="small" />}
            path="/settings/facility"
            tab="settings-facility"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Facility settings")}
          </MenuLink>
          <MenuLink
            submenu
            key="action-settings-kiosks"
            icon={<ComputerOutlined fontSize="small" />}
            selectedIcon={<Computer fontSize="small" />}
            path="/settings/kiosks"
            tab="settings-kiosks"
            selectedTab={selectedTab}
            onClick={onClick}
          >
            {t("Kiosks")}
          </MenuLink>
          {selectedFacility.features.includes(FacilityFeature.PhoneCall) && (
            <MenuLink
              submenu
              key="action-settings-phone-calls"
              icon={<ListAltOutlined fontSize="small" />}
              selectedIcon={<ListAlt fontSize="small" />}
              path="/settings/phone-calls"
              tab="settings-phone-calls"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Outside services")}
            </MenuLink>
          )}
          {featureEnabled("TEST_CALLS") && (
            <MenuLink
              submenu
              key="action-settings-network"
              icon={<RouterOutlined fontSize="small" />}
              selectedIcon={<Router fontSize="small" />}
              path="/settings/network"
              tab="settings-network"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Network")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(
            FacilityFeature.InPersonVisit
          ) && (
            <MenuLink
              submenu
              key="action-settings-in-person-visits"
              icon={<GroupOutlined fontSize="small" />}
              selectedIcon={<Group fontSize="small" />}
              path="/settings/in-person-visits"
              tab="settings-in-person-visits"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("In-person visits")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(
            FacilityFeature.ConfidentialInPersonVisit
          ) && (
            <MenuLink
              submenu
              key="action-settings-confidential-in-person-visits"
              icon={<GroupOutlined fontSize="small" />}
              selectedIcon={<Group fontSize="small" />}
              path="/settings/confidential-in-person-visits"
              tab="settings-confidential-in-person-visits"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Confidential in-person visits")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(FacilityFeature.VideoCall) && (
            <MenuLink
              submenu
              key="action-settings-video-calls"
              icon={<VideocamOutlined fontSize="small" />}
              selectedIcon={<Videocam fontSize="small" />}
              path="/settings/video-calls"
              tab="settings-video-calls"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Video calls")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(
            FacilityFeature.ConfidentialVideoCall
          ) && (
            <MenuLink
              submenu
              key="action-settings-confidential-video-calls"
              icon={<VideocamOutlined fontSize="small" />}
              selectedIcon={<Videocam fontSize="small" />}
              path="/settings/confidential-video-calls"
              tab="settings-confidential-video-calls"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Confidential video calls")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(FacilityFeature.Webinar) && (
            <MenuLink
              submenu
              key="action-settings-webinars"
              icon={<PresentToAllOutlined fontSize="small" />}
              selectedIcon={<PresentToAll fontSize="small" />}
              path="/settings/webinars"
              tab="settings-webinars"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Webinars")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(FacilityFeature.VoiceCall) && (
            <MenuLink
              submenu
              key="action-settings-voice-calls"
              icon={<PhoneEnabledOutlined fontSize="small" />}
              selectedIcon={<PhoneEnabled fontSize="small" />}
              path="/settings/voice-calls"
              tab="settings-voice-calls"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Voice calls")}
            </MenuLink>
          )}
          {selectedFacility.features.includes(FacilityFeature.Emessaging) && (
            <MenuLink
              submenu
              key="action-settings-messages"
              icon={<ChatOutlined fontSize="small" />}
              selectedIcon={<Chat fontSize="small" />}
              path="/settings/messages"
              tab="settings-messages"
              selectedTab={selectedTab}
              onClick={onClick}
            >
              {t("Messages")}
            </MenuLink>
          )}
        </MenuGroup>
        <MenuLink
          key="action-help-link"
          icon={<HelpOutlineOutlinedIcon fontSize="small" />}
          selectedIcon={<HelpIcon fontSize="small" />}
          tab="help"
          href="https://ameeliofordoc.zendesk.com/hc/en-us"
          rel="noopener noreferrer"
          target="_blank"
          selectedTab={selectedTab}
          onClick={onClick}
        >
          {t("Help")}
        </MenuLink>
      </MenuList>
    </MenuPaper>
  );
}
