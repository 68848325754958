import { belowLargeTablet, Button } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, useMediaQuery as measureScreenWidth } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import Descriptions from "@src/lib/Descriptions";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import isObjectWithKey from "@src/lib/isObjectWithKey";
import NotAllowed from "@src/lib/NotAllowed";
import PageHeader from "@src/lib/PageHeader";
import PageLoader from "@src/lib/PageLoader";
import ActivitySection from "@src/lib/Profiles/ActivitySection";
import ContactsSection from "@src/lib/Profiles/ContactsList";
import {
  getDisplayBirthday,
  hoursMinutesSecondsTextLabel,
} from "@src/lib/Time";
import useAvailableFilters, {
  FilterOption,
  FilterTypes,
} from "@src/lib/useAvailableFilters";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import { useReverseFetchAll } from "@src/lib/useFetchAll";
import { WRAPPER_STYLE } from "@src/styles/styles";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { GetInmateDocument, GetInmateQuery } from "./GetInmate.generated";
import { GetInmateMeetingsDocument } from "./GetInmateMeetings.generated";

type InmateActivityCardProps = {
  inmateId: string;
  inmate?: GetInmateQuery["inmate"];
};

const { history } = window;

const InmateActivityCard = ({ inmateId, inmate }: InmateActivityCardProps) => {
  const availableFilters = useAvailableFilters(inmate);
  // the meeting type filter is restored from page state for back navigation
  const [filter, setFilter] = useState<Record<string, FilterOption>>({
    meetingType: isObjectWithKey(history.state, "meetingType")
      ? (history.state.meetingType as FilterOption)
      : "all",
  });

  useEffect(() => {
    history.replaceState(filter, "");
  }, [filter]);

  const meetingTypes = FilterTypes[filter.meetingType];
  const { data, error, fetchMore } = useQuery(GetInmateMeetingsDocument, {
    variables: {
      inmateId: inmateId,
      // The query is skipped if meetingType is undefined, but
      // $meetingType in the query definition is required
      // because it needs to be passed into the array argument for meetingType
      // in the `meetings` field resolver, and that has it as required when included in the array
      meetingTypes: meetingTypes!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      cursor: undefined,
    },
    onError: errorReporter,
    skip: !meetingTypes,
    fetchPolicy: "cache-and-network",
  });

  useReverseFetchAll({
    pageInfo: data?.inmate.meetings.pageInfo,
    fetchMore,
  });

  const meetings = useMemo(
    () => data?.inmate.meetings.edges.map(({ node }) => node).reverse() || [],
    [data]
  );

  if (error) throw error;

  return (
    <ActivitySection
      meetings={meetings}
      filter={filter.meetingType}
      availableFilters={availableFilters}
      onFilterChange={(val) => {
        setFilter({ meetingType: val });
      }}
      inmate={inmate}
    />
  );
};

export default function InmatePage() {
  const { id: inmateId } = useParams<{ id: string }>();
  if (!inmateId) throw new Error("missing inmateId param");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useCurrentStaff();
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings
  );

  const { data, error } = useQuery(GetInmateDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      inmateId,
    },
    onError: errorReporter,
  });
  if (error) throw error;
  if (!data?.inmate) return <PageLoader />;

  const facilitySupportsFamilyMeetings =
    data?.inmate.facility.features.includes(FacilityFeature.VideoCall) ||
    data?.inmate.facility.features.includes(FacilityFeature.InPersonVisit);

  const inmate = data.inmate;
  const connections = data.inmate.connections;
  // NOTE: the database returns UTC values that should not be converted
  // to local time. this must not use `toLocalISODate()`.
  const formattedDOB = new Date(inmate.dateOfBirth).toISOString().split("T")[0];
  // Handling for case when DOB is null in DB but sent back from API as special '0001-01-01'
  const formattedInmateDOB =
    formattedDOB === "0001-01-01"
      ? t("Unknown")
      : getDisplayBirthday(formattedDOB);

  if (
    inmate &&
    !user.staffPositions.some(
      (position) => position.facility.id === inmate.facility.id
    )
  ) {
    return (
      <Box>
        <Header
          title={t("Not permitted")}
          subtitle={t(
            "This resident is at a facility that you do not have access to."
          )}
        />
        <NotAllowed />
      </Box>
    );
  }

  return (
    <Box>
      <PageHeader
        title={inmate.fullName}
        extra={[
          facilitySupportsFamilyMeetings && (
            <div key="schedule" className="flex gap-x-4">
              <Button
                variant="contained"
                disabled={!canManageVisitorsAndMeetings}
                disabledReason={t(
                  "You do not have permission to perform this function."
                )}
                onClick={() =>
                  canManageVisitorsAndMeetings &&
                  navigate(`/inmates/${inmate.id}/event/new`)
                }
              >
                {t("Schedule Meeting")}
              </Button>
            </div>
          ),
        ]}
        breadcrumb={
          <Breadcrumbs
            paths={[
              { path: "/inmates", label: t("Residents") },
              { path: `/inmates/${inmate.id}`, label: inmate.fullName },
            ]}
          />
        }
        onBack={() => window.history.back()}
      >
        <Descriptions column={isMobileOrSmallTablet ? 1 : 3}>
          <Descriptions.Item label={t("Name")}>
            {inmate.fullName}
          </Descriptions.Item>
          <Descriptions.Item label={t("Unique ID")}>
            {inmate.inmateIdentification}
          </Descriptions.Item>
          <Descriptions.Item label={t("DOB")}>
            {formattedInmateDOB}
          </Descriptions.Item>
          <Descriptions.Item label={t("Group")}>
            {inmate.group.name}
          </Descriptions.Item>
          {inmate.facility.features.includes(FacilityFeature.InPersonVisit) && (
            <Descriptions.Item label={t("Personal Visit Quota")}>
              {inmate.visitQuota}
            </Descriptions.Item>
          )}
          {inmate.facility.features.includes(FacilityFeature.VideoCall) && (
            <>
              <Descriptions.Item label={t("Personal Call Quota")}>
                {inmate.callQuota}
              </Descriptions.Item>
              <Descriptions.Item label={t("Needs Staff Monitor")}>
                {inmate.needsMonitor ? t("Yes") : t("No")}
              </Descriptions.Item>
            </>
          )}
          {inmate.facility.features.includes(FacilityFeature.VoiceCall) && (
            <Descriptions.Item label={t("Voice call minutes available")}>
              {`${hoursMinutesSecondsTextLabel(
                inmate.remainingVoiceCallTimeQuota
              )} / ${hoursMinutesSecondsTextLabel(inmate.voiceCallTimeQuota)}`}
            </Descriptions.Item>
          )}
        </Descriptions>
      </PageHeader>
      <div style={WRAPPER_STYLE}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: 4,
          }}
        >
          <Box sx={{ flex: { xs: "1 1 auto", lg: 3 } }}>
            <InmateActivityCard inmateId={inmateId} inmate={inmate} />
          </Box>

          <Box sx={{ flex: { xs: "1 1 auto", lg: 1 } }}>
            <ContactsSection connections={connections} type={"inmate"} />
          </Box>
        </Box>
      </div>
    </Box>
  );
}
