import { MeetingType } from "@src/api/graphql";
import featureEnabled from "@src/lib/featureEnabled";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useLiveCalls from "@src/lib/useLiveCalls";
import ActivityNew from "@src/pages/ActivityPage/ActivityNew";
import Activity from "./Activity";

export default function ActivityPage() {
  const { facility } = useGuaranteedFacilityContext();
  const liveCalls = useLiveCalls({
    facility,
    meetingTypes: [MeetingType.VideoCall],
  });

  return featureEnabled("NEW_ACTIVITY_PAGE") ? (
    <ActivityNew facility={facility} />
  ) : (
    <Activity calls={liveCalls} facility={facility} />
  );
}
