import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  SelectInput,
  SubmitButton,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Link, Stack, Typography } from "@mui/material";
import { appendItem } from "@src/api/client";
import { Entitlement, MessageKeywordCategory } from "@src/api/graphql";
import { DialogSkeleton } from "@src/lib/closet";
import errorReporter from "@src/lib/errorReporter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import { isRequiredString, mergeRules } from "@src/lib/validate";
import { format } from "date-fns";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import ConditionalTooltip from "../../lib/ConditionalTooltip";
import TextInput from "../../lib/TextInput";
import { CreateLocalMessageKeywordDocument } from "./CreateLocalMessageKeyword.generated";
import {
  GetMessageKeywordsDocument,
  GetMessageKeywordsQuery,
} from "./GetMessageKeywords.generated";
import { UpdateLocalMessageKeywordDocument } from "./UpdateLocalMessageKeyword.generated";

export type Keyword = GetMessageKeywordsQuery["facility"]["keywords"][0];

type TextFormData = {
  name: string;
  spellings: string;
  category: MessageKeywordCategory;
  description: string;
};

type Props = Omit<DialogProps, "open" | "onSubmit" | "title"> & {
  keyword?: Keyword;
  onClose: () => void;
};

export default function AddEditKeywordModal({
  keyword,
  onClose,
  children,
  ...rest
}: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    control,
    setFocus,
  } = useForm<TextFormData>({
    mode: "onChange",
    defaultValues: {
      name: keyword ? keyword.name : "",
      category: keyword ? keyword.category : MessageKeywordCategory.Violence,
      description: keyword ? keyword.description : "",
      spellings: keyword ? keyword.spellings.join(", ") : "",
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (keyword) setFocus("spellings");
      else setFocus("name");
    }, 0);
  }, [keyword, setFocus]);

  const [updateKeyword] = useMutation(UpdateLocalMessageKeywordDocument, {
    onError: errorReporter,
  });
  const [createKeywordMutation] = useMutation(
    CreateLocalMessageKeywordDocument,
    {
      onError: errorReporter,
      update: (cache, { data }) => {
        if (data) {
          cache.modify({
            id: cache.identify({ __typename: "Facility", id: facility.id }),
            fields: {
              keywords: appendItem(
                data.createLocalMessageKeyword.messageKeyword
              ),
            },
          });
        }
      },
    }
  );

  const { data: keywordsData } = useQuery(GetMessageKeywordsDocument, {
    variables: { id: facility.id },
  });

  const noOverlap = useCallback(
    (s: string) => {
      if (!keywordsData) return true;

      return !keywordsData.facility.keywords
        .filter((k) => k.isActive && (!keyword || k.id !== keyword.id))
        .some((k) => k.name === s || k.spellings.includes(s));
    },
    [keywordsData, keyword]
  );

  const onFormSubmit = handleSubmit(async (data) => {
    if (keyword) {
      updateKeyword({
        variables: {
          input: {
            messageKeywordId: keyword.id,
            description: data.description,
            spellings: data.spellings
              .split(",")
              .map((s) => s.trim())
              .filter((s) => s !== ""),
            category: data.category,
          },
        },
      });
    } else {
      createKeywordMutation({
        variables: {
          input: {
            name: data.name,
            description: data.description,
            spellings: data.spellings
              .split(",")
              .map((s) => s.trim())
              .filter((s) => s !== ""),
            category: data.category,
            facilityId: facility.id,
          },
        },
      });
    }
    onClose();
  });

  if (!keywordsData) return <DialogSkeleton />;

  return (
    <Dialog
      {...rest}
      title={t("Keyword management")}
      onClose={onClose}
      fullWidth
    >
      <form onSubmit={onFormSubmit}>
        <DialogContent sx={{ pt: 0 }}>
          <Stack spacing={2}>
            <Typography variant="body1">
              {t(
                "If a message contains one of these words it will be flagged for review before being sent."
              )}
            </Typography>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit keywords.")}
            >
              <TextInput
                sx={{ mt: 2, width: 1 }}
                control={control}
                name="name"
                label={t("Name")}
                disabled={!!keyword || !canEdit}
                placeholder={""}
                rules={mergeRules(
                  isRequiredString(t("Please provide a valid keyword.")),
                  {
                    validate: {
                      noOverlap,
                    },
                  }
                )}
                helperText={
                  errors.name
                    ? errors.name.type === "noOverlap"
                      ? t(
                          "This keyword is already a spelling of an existing keyword."
                        )
                      : errors.name.type === "nonEmpty"
                      ? t("Name cannot be empty")
                      : undefined
                    : undefined
                }
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit keywords.")}
            >
              <TextInput
                sx={{ mt: 2, width: 1 }}
                control={control}
                name="spellings"
                label={t("Alternate spellings")}
                placeholder={""}
                disabled={!canEdit}
                rules={{
                  validate: {
                    noOverlaps: (s) =>
                      s
                        .split(",")
                        .map((x) => x.trim())
                        .filter((x) => x.length > 0)
                        .every(noOverlap),
                  },
                }}
                helperText={
                  errors.spellings
                    ? t(
                        "One of these spellings overlaps with an existing spelling."
                      )
                    : t("Separated by commas")
                }
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit keywords.")}
            >
              <SelectInput
                name="category"
                label={t("Tag")}
                control={control}
                disabled={!canEdit}
                items={[
                  {
                    value: MessageKeywordCategory.Drugs,
                    name: t("Drugs"),
                  },
                  {
                    value: MessageKeywordCategory.Escape,
                    name: t("Escape"),
                  },
                  {
                    value: MessageKeywordCategory.Hate,
                    name: t("Hate"),
                  },
                  {
                    value: MessageKeywordCategory.Violence,
                    name: t("Violence"),
                  },
                ]}
              />
            </ConditionalTooltip>
            <ConditionalTooltip
              active={!canEdit}
              title={t("You do not have permission to edit keywords.")}
            >
              <TextInput
                sx={{ mt: 2, width: 1 }}
                control={control}
                disabled={!canEdit}
                name="description"
                label={t("Comments")}
                placeholder={""}
              />
            </ConditionalTooltip>
            {keyword && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                }}
              >
                <Box>
                  <Typography>{t("Created By")}</Typography>
                  {keyword.createdBy ? (
                    <Link
                      component={RouterLink}
                      to={`/staff/${keyword.createdBy.staff.id}`}
                    >
                      {keyword.createdBy.staff.fullName}
                    </Link>
                  ) : (
                    t("Unknown")
                  )}
                </Box>

                <Box>
                  <Typography>{t("Created On")}</Typography>
                  {keyword.createdAt
                    ? format(new Date(keyword.createdAt), "M/d/y")
                    : t("Unknown")}
                </Box>

                <Box>
                  <Typography>{t("Last Edited By")}</Typography>
                  {keyword.lastUpdatedBy ? (
                    <Link
                      component={RouterLink}
                      to={`/staff/${keyword.lastUpdatedBy.staff.id}`}
                    >
                      {keyword.lastUpdatedBy.staff.fullName}
                    </Link>
                  ) : (
                    t("Unknown")
                  )}
                </Box>

                <Box>
                  <Typography>{t("Last Edited On")}</Typography>
                  {keyword.updatedAt
                    ? format(new Date(keyword.updatedAt), "M/d/y")
                    : t("Unknown")}
                </Box>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          {canEdit ? (
            <>
              <Button variant="outlined" autoFocus onClick={onClose}>
                {t("Cancel")}
              </Button>
              <SubmitButton disabled={!isValid} submitting={isSubmitting}>
                {t("Save")}
              </SubmitButton>
            </>
          ) : (
            <Button variant="contained" autoFocus onClick={onClose}>
              {t("Close")}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
