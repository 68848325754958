import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/react";
import i18n from "@src/i18n/i18nConfig";
import { showToast } from "./UI";

// used for errors that do not bubble up to the error boundary
// reports to sentry and shows a toast
export const reportError = (error: ApolloError | Error) => {
  Sentry.captureException(error);
  showToast(
    error.message === "Internal Server Error"
      ? i18n.t(
          "An unexpected error occurred. Our engineering team has been notified."
        )
      : error.message,
    "error"
  );
};

const errorReporter = (error: ApolloError | Error) => reportError(error);
export default errorReporter;
