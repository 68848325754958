import { sortByStrings } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { MeetingType } from "@src/api/graphql";
import errorReporter from "@src/lib/errorReporter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { ScreenSubtitle, ScreenTitle } from "@src/lib/typography";
import { useTranslation } from "react-i18next";
import { CardSkeleton, TitleSkeleton } from "../../lib/closet";
import { GetFacilityGroupsAndKiosksDocument } from "../../lib/ScheduleTable/AddEditScheduleModal/GetFacilityGroupsAndKiosks.generated";
import KiosksTable from "./KiosksTable";

export default function FacilityKioskSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();

  const { data: groupsKiosksData } = useQuery(
    GetFacilityGroupsAndKiosksDocument,
    {
      variables: {
        facilityId: facility.id,
      },
      onError: errorReporter,
    }
  );

  if (!groupsKiosksData)
    return (
      <>
        <TitleSkeleton />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <CardSkeleton />
          <CardSkeleton />
        </Stack>
      </>
    );

  const videoKiosks = sortByStrings(
    groupsKiosksData.facility.kiosks.filter(
      (k) => k.meetingType === MeetingType.VideoCall
    ),
    (r) => r.name
  );
  const ipvKiosks = sortByStrings(
    groupsKiosksData.facility.kiosks.filter(
      (k) => k.meetingType === MeetingType.InPersonVisit
    ),
    (r) => r.name
  );

  return (
    <>
      <ScreenTitle>{t("Kiosks")}</ScreenTitle>
      <ScreenSubtitle>
        {t("Reservable resources for in-person or video visits.")}
      </ScreenSubtitle>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <KiosksTable kiosks={videoKiosks} isInPerson={false} />
        <KiosksTable kiosks={ipvKiosks} isInPerson />
      </Stack>
    </>
  );
}
