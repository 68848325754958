import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import { isRequired } from "@src/lib/validate";
import { useState } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateInput from "../../DateInput";

type PartialData = { expiresAt: string | null };

type Props<Data extends PartialData> = {
  control: Control<Data>;
};

export default function ExpiresAtInput<Data extends PartialData>({
  control,
}: Props<Data>) {
  const { t } = useTranslation();
  const [willExpire, setWillExpire] = useState(false);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            value={willExpire ? "yes" : "no"}
            onChange={(_, isChecked) => setWillExpire(isChecked)}
          />
        }
        label={t("Set end date")}
      />
      {willExpire ? (
        <DateInput
          control={control}
          // TODO: there is some typing bug in withController :sob:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name={"expiresAt" as any}
          label={t("Remove after")}
          disablePast
          rules={isRequired(t("Please specify an end date."))}
        />
      ) : (
        <Alert severity="warning">
          {t(
            "Without an end date, this restriction will persist indefinitely until staff remove it. Please ensure the explanation documents the necessary details to facilitate review."
          )}
        </Alert>
      )}
    </>
  );
}
