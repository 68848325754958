import { Descriptions as Base } from "antd";
import React from "react";

type Props = {
  column?: number;
  children?: React.ReactNode;
};

function Descriptions({ column, children }: Props) {
  return (
    <Base size="small" column={column}>
      {children}
    </Base>
  );
}

Descriptions.Item = function Item({
  label,
  labelStyle,
  children,
  style,
}: {
  label: string;
  labelStyle?: React.CSSProperties;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <Base.Item label={label} style={style} labelStyle={labelStyle}>
      {children}
    </Base.Item>
  );
};

export default Descriptions;
