import { groupBy } from "@ameelio/core";
import { useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Entitlement, FacilityFeature, MessageStatus } from "@src/api/graphql";
import { GetConnectionMessagesDocument } from "@src/graphql/GetConnectionMessages.generated";
import errorReporter from "@src/lib/errorReporter";
import Header from "@src/lib/Header";
import { Message } from "@src/lib/Message";
import NotAllowed from "@src/lib/NotAllowed";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useEntitlement from "@src/lib/useEntitlement";
import useFacilityFeature from "@src/lib/useFacilityFeature";
import useMountedPolling from "@src/lib/useMountedPolling";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { ApproveMessageDocument } from "./ApproveMessage.generated";
import { GetPendingMessagesDocument } from "./GetPendingMessages.generated";
import MessageReviewPanel from "./MessageReviewPanel";
import { TConnectionListItem } from "./MessageReviewPanel/ConnectionListSidebar/ConnectionList";
import { RejectMessageDocument } from "./RejectMessage.generated";

const MessageReviewPage = () => {
  const {
    facility: { id: facilityId },
  } = useGuaranteedFacilityContext();

  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const facilityhasMessaging = useFacilityFeature(FacilityFeature.Emessaging);

  const [selectedConnection, setSelectedConnection] =
    useState<TConnectionListItem>();
  const [flaggedMessage, setFlaggedMessage] = useState<Message>();

  const canReviewEmessages = useEntitlement(Entitlement.ReviewEmessages);

  // This query includes { facility { pendingMessages {count} } } in order to update the apollo cache and the badge count in the menu.
  const { data: queryConnectionData, refetch: refetchConnectionMessages } =
    useQuery(GetConnectionMessagesDocument, {
      variables: { id: selectedConnection?.id || "" },
      skip: !selectedConnection || !canReviewEmessages,
      onError: errorReporter,
    });

  const {
    loading,
    data: pendingMessageData,
    networkStatus,
    startPolling,
    stopPolling,
  } = useQuery(GetPendingMessagesDocument, {
    fetchPolicy: "cache-and-network",
    variables: { id: facilityId },
    onError: errorReporter,
    onCompleted: async (data) => {
      // refetch uses the original value if no args are passed
      // thus, need to ensure a connection is actively being reviewed
      if (selectedConnection) {
        await refetchConnectionMessages();
      }
    },
    // learning: polling only triggers onCompleted when this flag is true
    // https://github.com/apollographql/apollo-client/issues/5531#issuecomment-568235629
    notifyOnNetworkStatusChange: true,
    skip: !canReviewEmessages,
  });
  useMountedPolling(30000, startPolling, stopPolling);

  const flaggedMessageByConnection = useMemo(() => {
    if (!pendingMessageData) return {};
    // filtering again by pending review allows us to update the UI leveraging the cache
    // without making an extra network request to GetPendingMessage after every mutation
    const messages = pendingMessageData.facility.pendingMessages.edges
      .map(({ node }) => node)
      .filter((m) => m.status === MessageStatus.PendingReview);

    return groupBy(messages, (m) => m.connection.id);
  }, [pendingMessageData]);

  const initializeNewConnectionReview = useCallback(() => {
    const defaultConnectionMessages = Object.values(
      flaggedMessageByConnection
    )[0];
    if (!defaultConnectionMessages) return;
    setSelectedConnection(defaultConnectionMessages[0].connection);
  }, [flaggedMessageByConnection]);

  const initializeFlaggedMessage = useCallback(() => {
    if (!selectedConnection) return;

    const messages = flaggedMessageByConnection[selectedConnection.id];

    if (!messages || !messages.length) return;

    setFlaggedMessage(messages[0]);
  }, [selectedConnection, flaggedMessageByConnection]);

  useEffect(() => {
    // initialize screen upon first loading the pending message data
    if (
      !selectedConnection &&
      Object.keys(flaggedMessageByConnection).length > 0
    ) {
      initializeNewConnectionReview();
    }
  }, [
    flaggedMessageByConnection,
    selectedConnection,
    initializeNewConnectionReview,
  ]);

  // initializes the first message whenever the selected connection changes
  useEffect(() => {
    if (selectedConnection) {
      initializeFlaggedMessage();
    }
  }, [selectedConnection, initializeFlaggedMessage]);

  const onReviewComplete = () => {
    if (!selectedConnection) return;

    //  if there are no pending messages, just reset
    if (Object.keys(flaggedMessageByConnection).length === 0) {
      snackbarContext.alert(
        "success",
        t("You have reviewed all of the pending messages 🎉!")
      );
      setFlaggedMessage(undefined);
      setSelectedConnection(undefined);
      return;
    }

    // if the selected connection  still has messages that need review, move to the next one
    const selectedConnectionMessages =
      flaggedMessageByConnection[selectedConnection.id];
    if (selectedConnectionMessages) {
      setFlaggedMessage(selectedConnectionMessages[0]);
      return;
    }

    //  else, initialize the next connection
    snackbarContext.alert(
      "success",
      t("You are done reviewing this thread! Onto the next one 🎉.")
    );
    initializeNewConnectionReview();
  };

  const [approveMessage, { loading: approveLoading }] = useMutation(
    ApproveMessageDocument,
    {
      onCompleted: onReviewComplete,
      onError: errorReporter,
    }
  );

  const [rejectMessage, { loading: rejectLoading }] = useMutation(
    RejectMessageDocument,
    {
      onCompleted: onReviewComplete,
      onError: errorReporter,
    }
  );

  const onSelect = (connection: TConnectionListItem) => {
    setSelectedConnection(connection);
  };

  const selectedConnectionMessages =
    queryConnectionData?.connection.messages.edges.map(({ node }) => node) ||
    [];

  if (!facilityhasMessaging) return <Navigate to={{ pathname: "/" }} />;

  const flaggedMessagesForSelectedConnection = selectedConnection
    ? flaggedMessageByConnection[selectedConnection.id]
    : undefined;

  if (loading && !pendingMessageData) {
    return <PageLoader />;
  }

  return (
    <div className="h-screen">
      <Header
        title={t("Review Flagged Messages")}
        subtitle={t(
          "Review pending message requests and manage keywords and other settings."
        )}
      />
      {!canReviewEmessages ? (
        <NotAllowed />
      ) : (
        <MessageReviewPanel
          flaggedMessageMap={flaggedMessageByConnection}
          onSelect={onSelect}
          selected={
            selectedConnection &&
            flaggedMessage &&
            flaggedMessagesForSelectedConnection
              ? {
                  connection: selectedConnection,
                  flaggedMessage,
                  messages: selectedConnectionMessages,
                  totalFlaggedMessages:
                    flaggedMessagesForSelectedConnection.length,
                }
              : undefined
          }
          onApprove={() =>
            flaggedMessage &&
            approveMessage({
              variables: { input: { messageId: flaggedMessage.id } },
            })
          }
          onReject={(reason, internalComments) =>
            flaggedMessage &&
            rejectMessage({
              variables: {
                input: {
                  messageId: flaggedMessage.id,
                  statusDetails: reason,
                  internalComments,
                },
              },
            })
          }
          loading={rejectLoading || approveLoading}
        />
      )}
    </div>
  );
};

export default MessageReviewPage;
