import { Breadcrumb, PageHeader as Base } from "antd";
import React from "react";

type PageHeaderProps = {
  onBack?: () => void;
  title: string | React.ReactNode;
  className?: string;
  breadcrumb?: React.ReactElement<typeof Breadcrumb>;
  children?: React.ReactNode;
  extra?: React.ReactNode;
};

export default function PageHeader({
  onBack,
  title,
  breadcrumb,
  extra,
  className,
  children,
}: PageHeaderProps) {
  return (
    <Base
      ghost={false}
      className={className}
      onBack={onBack}
      title={title}
      breadcrumb={breadcrumb}
      extra={extra}
    >
      {children}
    </Base>
  );
}
